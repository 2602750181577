import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  public order;

  constructor() { }

  getOrder(){
    return this.order;
  }

  setOrder(order){
    this.order = order;
  }
}
