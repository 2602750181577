import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';

//ANGULAR
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//CAROUSEL
import { OwlModule } from 'ngx-owl-carousel';

// MATERIAL
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule, MatNativeDateModule, MatDatepickerModule, MatSnackBarModule, MatChipsModule,  MatButtonModule,MatRadioModule, MatSlideToggleModule, MatSidenavModule, MatCardModule,MatGridListModule, MatMenuModule, MatToolbarModule, MatCheckboxModule, MatDialogModule, MatTableModule, MatPaginatorModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule  } from '@angular/material/form-field'
import { MatSliderModule } from '@angular/material/slider';

//PAGES
import { HomeComponent, MyDateAdapter } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ServicesComponent } from './pages/services/services.component';
import { InformationComponent } from './pages/information/information.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AccessComponent } from './pages/access/access.component';
import { DestinationComponent } from './pages/destination/destination/destination.component';
import { DestinationDetailComponent } from './pages/destination/destination-detail/destination-detail.component';
import { BookingCheckoutComponent } from './pages/booking/booking-checkout/booking-checkout.component';
import { BookingStructureComponent } from './pages/booking/booking-structure/booking-structure.component';
import { BookingResultComponent } from './pages/booking/booking-result/booking-result.component';
import { BookingRoomDetailComponent } from './pages/booking/booking-room-detail/booking-room-detail.component';
import { UserUpdateComponent } from './pages/user/user-update/user-update.component';
import { UserReservationComponent } from './pages/user/user-reservation/user-reservation.component';
import { UserReservationDetailComponent } from './pages/user/user-reservation-detail/user-reservation-detail.component';
import { BookingStructureDetailComponent } from './pages/booking/booking-structure-detail/booking-structure-detail.component';
import { BookingServiceDetailComponent } from './pages/booking-service/booking-service-detail/booking-service-detail.component';
import { BookingServiceComponent } from './pages/booking-service/booking-service/booking-service.component';
import { BookingServiceResultComponent } from './pages/booking-service/booking-service-result/booking-service-result.component';
import { BookingServiceCheckoutComponent } from './pages/booking-service/booking-service-checkout/booking-service-checkout.component';
import { UserReservationserviceDetailComponent } from './pages/user/user-reservationservice-detail/user-reservationservice-detail.component';
import { BookingAttivitaComponent } from './pages/booking-attivita/booking-attivita/booking-attivita.component';
import { BookingAttivitaDetailComponent } from './pages/booking-attivita/booking-attivita-detail/booking-attivita-detail.component';
import { BookingAttivitaCheckoutComponent } from './pages/booking-attivita/booking-attivita-checkout/booking-attivita-checkout.component';
import { QuestComponent } from './pages/quest/quest.component';

//MODULES
import { ModalMapComponent } from './modules/struttura/modal-map/modal-map.component';
import { ModalMapServizioComponent } from './modules/servizio/modal-map-servizio/modal-map-servizio.component';
import { ModalRegolamentoComponent } from './modules/struttura/modal-regolamento/modal-regolamento.component';
import { ModalRegolamentoservizioComponent } from './modules/servizio/modal-regolamentoservizio/modal-regolamentoservizio.component';
import { ModalMessageComponent } from './modules/struttura/modal-message/modal-message.component';
import { FooterComponent } from './modules/footer/footer.component';
import { HeaderComponent } from './modules/header/header.component';

//SERVICE
import { AuthService } from './service/auth/auth.service';
import { OrderService } from './service/order/order.service';

//FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

import { AgmCoreModule } from '@agm/core';

import { ChartsModule } from 'ng2-charts';


var firebaseConfig = {
  apiKey: "AIzaSyCW7-VeJtxkdSynTTlwhCXwyjqXCClT3JY",
  authDomain: "tourbooking-a1ac6.firebaseapp.com",
  databaseURL: "https://tourbooking-a1ac6.firebaseio.com",
  projectId: "tourbooking-a1ac6",
  storageBucket: "tourbooking-a1ac6.appspot.com",
  messagingSenderId: "843331603035",
  appId: "1:843331603035:web:b465f69f6c9f045512cd7e",
  measurementId: "G-V1JLF6DVXK"
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    ServicesComponent,
    InformationComponent,
    TermsComponent,
    AccessComponent,
    DestinationComponent,
    DestinationDetailComponent,
    BookingCheckoutComponent,
    BookingStructureComponent,
    ModalMapComponent,
    ModalMapServizioComponent,
    FooterComponent,
    HeaderComponent,
    BookingResultComponent,
    BookingStructureDetailComponent,
    BookingRoomDetailComponent,
    ModalRegolamentoComponent,
    UserUpdateComponent,
    UserReservationComponent,
    UserReservationDetailComponent,
    BookingServiceDetailComponent,
    BookingServiceComponent,
    BookingServiceResultComponent,
    BookingServiceCheckoutComponent,
    ModalMessageComponent,
    UserReservationserviceDetailComponent,
    ModalRegolamentoservizioComponent,
    BookingAttivitaComponent,
    BookingAttivitaDetailComponent,
    BookingAttivitaCheckoutComponent,
    QuestComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    AngularFirestoreModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    HttpModule,
    HttpClientModule,
    OwlModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXeO6OZez8_J95_DptlEwrCEpuMgrd3R8'
   })
  ],
  providers: [
    AuthService,
    OrderService,
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: DateAdapter, useClass: MyDateAdapter },
    AngularFireAuthGuard,
  ],
  entryComponents:[
    ModalMapComponent,
    ModalMapServizioComponent,
    ModalRegolamentoComponent,
    ModalRegolamentoservizioComponent,
    ModalMessageComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
