import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFireAuth } from '@angular/fire/auth';
import { OrderService } from '../../../service/order/order.service';

@Component({
  selector: 'app-booking-service-detail',
  templateUrl: './booking-service-detail.component.html',
  styleUrls: ['./booking-service-detail.component.scss']
})
export class BookingServiceDetailComponent implements OnInit {
  
  objectKey = Object.keys; 

  public language;

  public checkin;
  public checkout;
  public paese = '';
  public datainizio;
  public datafine;

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public id_servizio;
  public servizio;
  public imgCamera = {};
  public imgStruttura = {};
  
  //search
  public inputSearch;
  public searchTable = false;
  public resultsPaese;
  public comuni;

  public adulti;
  public bambini;

  public giorno = "1";

  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, public orderService: OrderService, public dialog: MatDialog, public afAuth: AngularFireAuth, public db: AngularFireDatabase,) { 
    this.getComuni();
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.id_servizio = params['id_servizio'];
      this.checkin = params['checkin'];
      this.checkout = params['checkout'];
      this.adulti = params['adulti'];
      this.bambini = params['bambini'];

      console.log(this.adulti);
      if(this.adulti=='undefined'){
        this.adulti = 1;
      }

      if(this.bambini=='undefined'){
        this.bambini=0;
      }

      console.log(this.id_servizio);
      this.getServiceDetail();
      this.getRoomAvailability();

      //riconverto la data di checkin in data visualizzabile sul calendario
      var offset = new Date().getTimezoneOffset()*60;
      let datain = (parseInt(this.checkin)+offset)*1000;
      this.datainizio = new Date(datain);

      //riconverto la data di checkout in data visualizzabile sul calendario
      var offset = new Date().getTimezoneOffset()*60;
      let dataout = (parseInt(this.checkout)+offset)*1000;
      this.datafine = new Date(dataout);
      
    });
  }

  public userData;
  ngOnInit() {
    window.scroll(0,0);
    var self = this;
    this.afAuth.auth.onAuthStateChanged( user => {
      if(user){
        console.log(user);
        self.userData = user;
        self.db.object('/users/'+user.uid).valueChanges().subscribe(action=>{
          console.log(action);
          if(action){
            self.userData = action;
          }
        });
      }
    });
  }

  //le ultime 4 strutture inserite e validate
  getServiceDetail(){
    var self = this;
    self.servizio = null;
    self.imgCamera = [];

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/apiserviziodetail?id_servizio='+this.id_servizio)
    .subscribe(
      res => {
        console.log(res);
        self.servizio = res;
        console.log(self.servizio);
        if(res[2]){
          var img = res[2];
          for(let i=0; i<img.length; i++){
            if(img[i].id_serviziodetail_immagine){
              var id = img[i].id_serviziodetail_immagine;
              self.imgCamera[id] = img[i].url_immagine;
            }else{
              var id = img[i].id_immagine;
              self.imgStruttura[id] = img[i].url_immagine;
            }
          }
        }
        console.log(self.imgCamera);
        self.getPrezzo();
      },
      err => {
        console.log(err);         
      }
    )
  }  

  public availability;
  public viewAvai = false;
  getRoomAvailability(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/servizio/apiservizio/apiroomavailability?id_servizio='+this.id_servizio+'&checkin='+this.checkin+'&checkout='+this.checkout);
    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/apiroomavailability?id_servizio='+this.id_servizio+'&checkin='+this.checkin+'&checkout='+this.checkout, options)
    .subscribe(
      res => {
        console.log(res);
        self.viewAvai= true;
        self.availability = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  
  public prezzoCamera;
  getPrezzo(){
    var camera = this.servizio[1];
    for(let i=0; i<camera.length; i++){
      var prezzo = parseFloat(camera[i].prezzo_serviziodetail);
      console.log(prezzo);
      if(i==0){
        this.prezzoCamera = prezzo;
      }else{
        if(prezzo<this.prezzoCamera){
          this.prezzoCamera = prezzo;
        }
      }
    }
  }
  
  getComuni(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiviewallcomuni')
    .subscribe(
      res => {
        self.comuni = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  //funzione che mi ricerca i paesi in base all'input
  searchCity($event){
    var self = this;
    let output = [];
    if($event){
      $event = $event.toLowerCase();
      this.comuni.forEach(a => {
        var nome = a.Comune.toLowerCase();
        var n = nome.includes($event);
        if(n){
          output.push(a)
        }
      })

      self.resultsPaese = output;
      self.searchTable = true;
    }
  }

  selectComune(item){
    this.searchTable = false;
    this.inputSearch = item.Comune;
    console.log(item)
  }

  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkin1 = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;

      
      //var dt = event.value.getTime()/1000;
      //this.checkin=dt;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkin=this.checkin-offset;
      this.datainizio=event.value;
      this.checkin = checkin1;
      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      //this.checkout = event.value.getTime()/1000;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkout=this.checkout-offset;

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkout = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;
      this.checkout = checkout;

      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }

  filterDates = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if (timezone < 0)
     selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    else 
      selectedDay = d.setUTCHours(0,0,0,0);

    const today = (new Date()).setUTCHours(0,0,0,0);

    if (selectedDay >= today) {
      return true;
    }else
      return false;
  }

  filterDatesOut = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if(timezone < 0){
      selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    }
    else{
      selectedDay = d.setUTCHours(0,0,0,0);
    }

    if(this.checkin){
      if(selectedDay <= this.checkin*1000){
        return false;
      }
    }
    return true;
  }

  search(){
    if(this.bambini==undefined || this.bambini==null || !this.bambini || this.bambini=='null'){
      this.bambini = 0;
    }

    if(this.adulti==undefined || this.adulti==null || !this.adulti || this.adulti=='mull'){
      this.adulti=1;
    }

    if(this.giorno=='2'){
      if(this.checkin && this.checkout){
        console.log(this.checkin);
        console.log(this.checkout);
        console.log(this.inputSearch);

        //this.router.navigate(['/booking-service-detail/'+this.language+'/'+this.id_servizio+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
        this.getServiceDetail();
        this.getRoomAvailability();
      }
    }else{
      if(this.checkin){
        this.checkout = this.checkin;
        //this.router.navigate(['/booking-service-detail/'+this.language+'/'+this.id_servizio+'/'+this.checkin+'/'+this.checkin+'/'+this.adulti+'/'+this.bambini]);
        this.getServiceDetail();
        this.getRoomAvailability();
      }
    }
  }

  gotoBookingRoomDetail(id_serviziodetail){
    console.log(id_serviziodetail);
    console.log(this.userData);
    if(this.userData){
      this.router.navigate(['/booking-service-checkout/'+this.language+'/'+this.id_servizio+'/'+id_serviziodetail+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
    }else{

      var order = {
        type: 'servizio',
        id_servizio : this.id_servizio,
        id_serviziodetail: id_serviziodetail,
        checkin: this.checkin,
        checkout: this.checkout,
        adulti: this.adulti,
        bambini: this.bambini,
      }

      this.orderService.setOrder(order);
      this.router.navigate(['/access/'+this.language+'/login']);
    }

  }
  
  openModalMap(){
    let dialogRef = this.dialog.open(ModalMapComponent,{
      width: '650px',
      height: '550px',
      disableClose: false,
      data:{
        item: this.servizio[0][0]
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
