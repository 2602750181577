import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapServizioComponent } from '../../../modules/servizio/modal-map-servizio/modal-map-servizio.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalMessageComponent } from '../../../modules/struttura/modal-message/modal-message.component';

@Component({
  selector: 'app-booking-service-result',
  templateUrl: './booking-service-result.component.html',
  styleUrls: ['./booking-service-result.component.scss']
})
export class BookingServiceResultComponent implements OnInit {

  public language;
  public type;

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public results;

  //mappa
  public mappa = false;
  public zoom = 6;
  public latMap = 41.9064878;
  public lonMap = 12.4536413;
  public coords = [];

  public checkin;
  public checkout;
  public paese = '';

  public adulti;
  public bambini;

  public datainizio;
  public datafine;

  //search
  public inputSearch;
  public searchTable = false;
  public resultsPaese;
  public comuni;

  public giorno = "1";

  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, public dialog: MatDialog, private _snackBar: MatSnackBar) { 
    this.getComuni();
    this.getImmagini();

    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.type = params['type'];

      this.language = params['language'];
      this.checkin = params['checkin'];
      this.checkout = params['checkout'];
      var paese = params['paese'];
      this.adulti = params['adulti'];
      this.bambini = params['bambini'];
      console.log(this.paese);

      if(paese){
        this.paese = paese;
        this.inputSearch = paese;
      }
      this.getResult();

      //riconverto la data di checkin in data visualizzabile sul calendario
      var offset = new Date().getTimezoneOffset()*60;
      let datain = (parseInt(this.checkin)+offset)*1000;
      this.datainizio = new Date(datain);

      if(this.checkout){
        //riconverto la data di checkout in data visualizzabile sul calendario
        var offset = new Date().getTimezoneOffset()*60;
        let dataout = (parseInt(this.checkout)+offset)*1000;
        this.datafine = new Date(dataout);
      }

      if(!this.checkin && !this.checkout){
        this.goModalMessage();
      }
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  public listMarker:any = { };
  markerClicked($event){
    console.log($event);
  }

  gotoUrl(id){
    this.router.navigate(['/booking-service-detail/'+this.language+'/'+id]);
  }

  gotoBookingServiceDetail(id_servizio){
    if(this.checkin && this.checkout){
      console.log(id_servizio);
      this.router.navigate(['/booking-service-detail/'+this.language+'/'+id_servizio+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
    }else{
      this.goModalMessage();
    }
  }

  search(){
    if(this.checkin){
      console.log(this.giorno);
      if((this.checkout==undefined || this.checkout=='undefined' || this.checkout==null || !this.checkout) && this.giorno=='2'){
        var message = 'Inserisci la data di checkout';
        this.openSnackBar(message, 'ok');
      }else{
        console.log(this.checkin);
        console.log(this.checkout);
        console.log(this.inputSearch);
        var paese = '';
        if(this.inputSearch){
          paese = this.inputSearch;
        }
  
        if(this.giorno=='1'){
          this.checkout = this.checkin;
        }
  
        if(this.bambini==undefined || this.bambini==null || !this.bambini || this.bambini=='null'){
          this.bambini = 0;
        }
  
        if(this.adulti==undefined || this.adulti==null || !this.adulti || this.adulti=='null'){
          this.adulti=1;
        }
        
        if(paese){
          this.router.navigate(['/booking-service-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+paese+'/'+this.adulti+'/'+this.bambini]);
        }else{
          this.router.navigate(['/booking-service-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
        }
      }

    }else{
      var message = 'Inserisci la data di checkin';
      this.openSnackBar(message, 'ok');
    }
  }

  getResult(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/search?checkin='+this.checkin+'&checkout='+this.checkout+'&paese='+this.paese, options)
    .subscribe(
      res => {
        console.log(res);
        self.results = res;

        self.results.forEach(element => {
          console.log(element);
          var doc = {
            id: element.id_servizio,
            zIndex : 1,
            lat: element.lat_servizio,
            lng: element.lng_servizio,
            iconUrl : {
              url: 'assets/marker/arancio.svg',
              scaledSize: {
                width: 40,
                height: 40,
              },
            }
          }

          self.listMarker[ element.id_servizio] = {
            id: element.id_servizio,
            title: element.nome_servizio,
            citta: element.citta_servizio,
          };
          self.coords.push(doc);
        });
      },
      err => {
        console.log(err);         
      }
    )
  }

  public imgviewall = {};
  getImmagini(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/viewallimg', options)
    .subscribe(
      res => {
        console.log(res);
        var imgviewall = res;

        
        for(let chiave in imgviewall){
          if(imgviewall[chiave].id_serviziodetail_immagine==null || imgviewall[chiave].id_serviziodetail_immagine==""){
            console.log(imgviewall[chiave].id_servizio_immagine);
            self.imgviewall[imgviewall[chiave].id_servizio_immagine] = imgviewall[chiave].url_immagine;
          }
        }
        console.log(imgviewall);
      },
      err => {
        console.log(err);         
      }
    )
  }

  getComuni(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiviewallcomuni')
    .subscribe(
      res => {
        self.comuni = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  //funzione che mi ricerca i paesi in base all'input
  searchCity($event){
    var self = this;
    let output = [];
    if($event){
      $event = $event.toLowerCase();
      this.comuni.forEach(a => {
        var nome = a.Comune.toLowerCase();
        var n = nome.includes($event);
        if(n){
          output.push(a)
        }
      })

      self.resultsPaese = output;
      self.searchTable = true;
    }
  }

  selectComune(item){
    this.searchTable = false;
    this.inputSearch = item.Comune;
    console.log(item)
  }

  
  goModalmap(item){
    let dialogRef = this.dialog.open(ModalMapServizioComponent, { 
      width: '600px',
      height: '400px',
      data: {
        item: item,
        language:this.language,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  goModalMessage(){
    let dialogRef = this.dialog.open(ModalMessageComponent, { 
      width: '600px',
      height: '400px',
      data: {
        message: 'Inserisci i dati di checkin, checkout, adulti e bambini per scoprire le disponibilità dei servizi.',
        language:this.language,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  
  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkin1 = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;

      
      //var dt = event.value.getTime()/1000;
      //this.checkin=dt;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkin=this.checkin-offset;
      this.datainizio=event.value;
      this.checkin = checkin1;
      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      //this.checkout = event.value.getTime()/1000;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkout=this.checkout-offset;

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkout = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;
      this.checkout = checkout;

      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }

  filterDates = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if (timezone < 0)
     selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    else 
      selectedDay = d.setUTCHours(0,0,0,0);

    const today = (new Date()).setUTCHours(0,0,0,0);

    if (selectedDay >= today) {
      return true;
    }else
      return false;
  }

  filterDatesOut = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if(timezone < 0){
      selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    }
    else{
      selectedDay = d.setUTCHours(0,0,0,0);
    }

    if(this.checkin){
      if(selectedDay <= this.checkin*1000){
        return false;
      }
    }
    return true;
  }

  gotoBookingStructureDetail(id_struttura){
    console.log(id_struttura);
    this.router.navigate(['/booking-structure-detail/'+this.language+'/'+id_struttura+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
