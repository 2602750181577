import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../service/auth/auth.service';
import { OrderService } from '../../service/order/order.service';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {

  public language;
  public type;

  public signupForm: FormGroup;
  public loginForm: FormGroup;
  public spinnerLogin = false;
  public message;

  constructor(private route: ActivatedRoute, private router: Router, public http: HttpClient, public orderService: OrderService, public formBuilder: FormBuilder, public authService: AuthService) {
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.type = params['type'];
    });

    this.loginForm = formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      password: new FormControl('', Validators.compose([Validators.required])),
    });

    this.signupForm = formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      password: new FormControl('', Validators.compose([Validators.required])),
      name: new FormControl('', Validators.compose([Validators.required])),
      surname: new FormControl('', Validators.compose([Validators.required])),
      condizione1: new FormControl(false),
    });
  }

  ngOnInit() {
  }

  gotoAccess(type){
    this.message = null;
    this.router.navigate(['/access/'+this.language+'/'+type]);
  }

  
  //AUTENTICAZIONE TRAMITE OPENPOST
  signupUser(){
    this.spinnerLogin=true;
    this.message=null;
    var self = this;
    if (!this.signupForm.valid || this.signupForm.value.condizione1==false){
      this.spinnerLogin=false;
      console.log(this.signupForm.value);
      this.message = 'Compila tutti i campi e accetta i termini.'
    } else {
      this.authService.signupUser(this.signupForm.value.email, this.signupForm.value.password, this.signupForm.value.name, this.signupForm.value.surname)
      .then(c => {
        self.sendEmail(this.signupForm.value.email);
        var order = this.orderService.getOrder();
        if(order){
          if(order.type=='struttura'){
            self.router.navigate(['/booking-checkout/'+this.language+'/'+order.id_struttura+'/'+order.id_camera+'/'+order.checkin+'/'+order.checkout+'/'+order.adulti+'/'+order.bambini]);
          }else{
            self.router.navigate(['/booking-service-checkout/'+this.language+'/'+order.id_servizio+'/'+order.id_serviziodetail+'/'+order.checkin+'/'+order.checkout+'/'+order.adulti+'/'+order.bambini]);
          }
          
        }else{
          self.router.navigate(['/home/it']);
        }
      }, (error) => {
          //errore durante l'autenticazione
          console.log(error);
          this.message=error.message;
          this.spinnerLogin=false;
      });
    }
  }


  login(){
    this.spinnerLogin=true;
    var self = this;
    this.message=null;
    if (!this.loginForm.valid){
      this.spinnerLogin=false;
      console.log(this.loginForm.value);
      this.message = 'Compila i campi email e password.';
    } else {
      this.authService.loginUser(this.loginForm.value.email, this.loginForm.value.password)
      .then( authData => {
        //redirect
        
        var order = this.orderService.getOrder();
        if(order){
          if(order.type=='struttura'){
            self.router.navigate(['/booking-checkout/'+this.language+'/'+order.id_struttura+'/'+order.id_camera+'/'+order.checkin+'/'+order.checkout+'/'+order.adulti+'/'+order.bambini]);
          }else{
            self.router.navigate(['/booking-service-checkout/'+this.language+'/'+order.id_servizio+'/'+order.id_serviziodetail+'/'+order.checkin+'/'+order.checkout+'/'+order.adulti+'/'+order.bambini]);
          }
        }else{
          self.router.navigate(['/home/it']);
        }
      }, error => {
        console.log(error);
        this.message = error.message;
        this.spinnerLogin=false;
      });
    }
  }

  public error;
  recupera(){
    this.spinnerLogin=true;
    this.error = null;
    this.message = null;
    var self = this;
    if (!this.loginForm.valid){
      console.log(this.loginForm.value);
      this.error = 'Inserisci un indirizzo email valido.';
      self.spinnerLogin=false;
    } else {
      this.authService.resetPassword(this.loginForm.value.email)
      .then((user) => {
        self.spinnerLogin=false;
        self.type='login';
        self.message = 'Abbiamo inviato una email con le istruzioni per poter resettare la tua password.';
      }, (error) => {
        self.spinnerLogin=false;
        self.error = error.message;
      });
    }
  }

  sendEmail(email){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    var body = {
        toEmail: email,
        textEmail:`<b>GRAZIE PER ESSERTI REGISTRATO AL NOSTRO PORTALE. TI DIAMO IL BENVENUTO</b><br>Le Informazioni legali sono presenti sul sito dove potrai trovare i termini e condizioni priavacy e cookie
        Sul nostro sito oltre a poter prenotare una delle tante strutture inserite potrà prenotare tanti utili servizi per avere una vacanza fantastica`,
        subjectEmail:"REGISTRAZIONE A TOURINGFLAT",
        showButtons: false
    }

    self.http.post('https://api.composingstudio.com/v1/touringflat/emails/customercare', body, options)
    .subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err); 
      }
    )
  }
}
