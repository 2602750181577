import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { ModalMapComponent } from '../../modules/struttura/modal-map/modal-map.component';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public language = 'it';
  public adulti = 2;
  public bambini = 0;

  @ViewChild('owlElement', {static:false}) owlElement: OwlCarousel;

  public imgGallery = [
    {
      path:'./assets/media/slide_home/slide_01.jpg',
    },
    {
      path:'./assets/media/slide_home/slide_02.jpg',
    },
    {
      path:'./assets/media/slide_home/slide_03.jpg',
    },
    {
      path:'./assets/media/slide_home/slide_04.jpg',
    },
  ];

  public urlbackend = 'https://managers.touringflat.com/web';
  //public urlbackend = 'http://localhost/touringflatbackend/web';
  
  public imamagini = {};
  public struttureapi;

  //tabella comuni
  public comuni;
  public searchTable = false;
  public resultsPaese;
  public inputSearch = null;

  //ricerca data
  public checkin;
  public checkout;
  public datainizio;
  public datafine;

  constructor(private router: Router,  private route: ActivatedRoute, private http: HttpClient, public dialog: MatDialog, private _snackBar: MatSnackBar) { 
    this.getResult();
    this.getComuni();
    this.getDestination();

    this.route.params.subscribe((params: ParamMap) => {
      var label = params['language'];
      if(label=='return'){
        window.open('https://managers.touringflat.com/web/index.php/');
      }
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  public destinazioniList;
  public immaginiList = {};
  getDestination(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/destinazione/apidestinazione/apidestinazioneviewall')
    .subscribe(
      res => {
        console.log(res);
        var result = res;
        if(result){
          self.destinazioniList = result[0];

          var img = result[1];
          for(let i=0; i<img.length; i++){
            if(img[i].id_destinazione_immagine){
              var id = img[i].id_destinazione_immagine;
              if(!self.immaginiList[id]){
                self.immaginiList[id] = img[i].url_immagine;
              }
            }
          }

          console.log(self.immaginiList);
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

  gotoQuest(){
    this.router.navigate(['/quest/'+this.language]);
  }
  gotoDestination(){
    this.router.navigate(['/destination/'+this.language]);
  }

  gotoDestinationDetail(destinationId){
    this.router.navigate(['/destination-detail/'+this.language+'/'+destinationId]);
  }

  async setUserClaims(uid: any, claims: any) {
    try {
      const api = 'http://localhost/sanzioni1.0api/web/index.php/targasystem/list';

      const data = await this.http.get(api, { headers: {  "Authorization": "Bearer lKdttOAO5MlhrJ0v4SYoIFnFbGS870W_", "processData": "false"}}).toPromise();
      console.log(data);
      return data;
    } catch (err) {
      return err;
    }
  }

  getPagopa(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    var body = {
      codIpaEnte:'CMBA',
      password:'QB1MLZILS0QC',
      flusso:'https://test.sanzioni.cloud/file/pagopa/IMPORTA_DOVUTO-INSERIMENTO_NEW.xml',
      flagGeneraIuv:'true',
    };

    self.http.post('https://gestione-servizi.it1.eur.aruba.jenv-aruba.cloud/v1/pago-pa/dovuto', body, options)
    .subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err);         
      }
    )
  }

  getResult(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturaviewall', options)
    .subscribe(
      res => {
        console.log(res);
        if(res){
          self.struttureapi = res[0];
          var imamagini = res[1];
          for(let i=0; i<imamagini.length; i++ ){
            if(!self.imamagini[imamagini[i].id_struttura_immagine]){
              self.imamagini[imamagini[i].id_struttura_immagine] = self.urlbackend+'/'+imamagini[i].url_immagine;
            }
          }
        }
      },
      err => {
        console.log(err);         
      }
    )
  }


  getComuni(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiviewallcomuni')
    .subscribe(
      res => {
        console.log(res);
        self.comuni = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  goModalmap(item){
    let dialogRef = this.dialog.open(ModalMapComponent, { 
      width: '600px',
      height: '400px',
      data: {
        item: item,
        language:this.language,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  //funzione che mi ricerca i paesi in base all'input
  searchCity($event){
    var self = this;
    let output = [];
    if($event){
      $event = $event.toLowerCase();
      this.comuni.forEach(a => {
        var nome = a.Comune.toLowerCase();
        var n = nome.includes($event);
        if(n){
          output.push(a)
        }
      })

      self.resultsPaese = output;
      self.searchTable = true;
    }
  }

  selectComune(item){
    this.searchTable = false;
    this.inputSearch = item.Comune;
    console.log(item)
  }

  search(){
    if(this.checkin && this.checkout){
      console.log(this.checkin);
      console.log(this.checkout);
      console.log(this.inputSearch);
      var paese = '';
      if(this.inputSearch){
        paese = this.inputSearch;
      }

      if(paese){
        this.router.navigate(['/booking-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+paese+'/'+this.adulti+'/'+this.bambini]);
      }else{
        this.router.navigate(['/booking-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
      }
    }else{
      var message = 'Inserisci la data di checkin e checkout';
      this.openSnackBar(message, 'ok');
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
  
  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkin1 = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;

      
      //var dt = event.value.getTime()/1000;
      //this.checkin=dt;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkin=this.checkin-offset;
      this.datainizio=event.value;
      this.checkin = checkin1;
      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      //this.checkout = event.value.getTime()/1000;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkout=this.checkout-offset;

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkout = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;
      this.checkout = checkout;

      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }

  /*
  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      var dt = event.value.getTime()/1000;
      this.checkin=dt;
      var offset = new Date().getTimezoneOffset()*60;
      this.checkin=this.checkin-offset;
      this.datainizio=event.value;

      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      this.checkout = event.value.getTime()/1000;
      var offset = new Date().getTimezoneOffset()*60;
      this.checkout=this.checkout-offset;
      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }
  */

  filterDates = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if (timezone < 0)
     selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    else 
      selectedDay = d.setUTCHours(0,0,0,0);

    const today = (new Date()).setUTCHours(0,0,0,0);

    if (selectedDay >= today) {
      return true;
    }else
      return false;
  }

  filterDatesOut = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if(timezone < 0){
      selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    }
    else{
      selectedDay = d.setUTCHours(0,0,0,0);
    }

    if(this.checkin){
      if(selectedDay <= this.checkin*1000){
        return false;
      }
    }
    return true;
  }

  gotoBookingStructureDetail(id_struttura){
    console.log(id_struttura);
    this.router.navigate(['/booking-structure-detail/'+this.language+'/'+id_struttura]);
  }
}



import { NativeDateAdapter, MatButtonToggleChange } from '@angular/material';
import { Injectable } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat == "input") {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let mese;
        if(month<10){
          mese = '0'+month; 
        }else{
          mese = month;
        }
        return day+'/'+ mese+'/'+year;
    } else {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let mese;
        if(month<10){
          mese = '0'+month; 
        }else{
          mese = month;
        }

        return day+'/'+ mese+'/'+year;
    }
  }
}