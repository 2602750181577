import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public language = 'it';

  public name;
  public surname;
  public email;
  public terms;

  constructor(private router: Router, private http: HttpClient,) { }

  ngOnInit() {
  }

  gotoLink(label){
    this.router.navigate(['/'+label+'/'+this.language]);
  }

  gotoUrl(label){
    window.open(label);
  }

  gotoContratto(){
    window.open('./assets/media/Contratto_Touringflat_Struttura.pdf');
  }

  gotoTerms(type){
    this.router.navigate(['/terms/'+this.language+'/'+type]);
  }

  sendEmail(email){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    var body = {
        toEmail: email,
        textEmail:`<b>Iscrizione newsletter.</b><br>
        Nome: `+self.name+`<br>
        Cognome: `+self.surname+`<br>
        Email: `+self.email+`<br>
        Iscrizione della newsletter`,
        subjectEmail:"NEWSLETTER",
        showButtons: false
    }

    self.http.post('https://api.composingstudio.com/v1/touringflat/emails/info', body, options)
    .subscribe(
      res => {
        console.log(res);
        self.messageOk = 'Iscrizione avvenuta con successo';
        self.message=null;
        self.name = null;
        self.surname = null;
        self.email = null;
        self.terms= false;
      },
      err => {
        console.log(err); 
        self.message = 'Si è verificato un errore.';
      }
    )
  }

  message;
  messageOk;
  sendNewsletter(){
    this.message = null;
    this.messageOk = null;
    if(this.name && this.surname && this.email){
      if(this.terms){
        this.sendEmail(this.email);        
      }else{
        this.message = 'Accetta termini e condizioni.';
      }
    }else{
      this.message = 'Inserisci nome, congome ed email.';
    }
  }
}
