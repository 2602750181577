import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-regolamento',
  templateUrl: './modal-regolamento.component.html',
  styleUrls: ['./modal-regolamento.component.scss']
})
export class ModalRegolamentoComponent implements OnInit {

  public structure;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ModalRegolamentoComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.structure = data.structure;
    console.log(this.structure);
  }

  ngOnInit() {

  }
}
