import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { FooterComponent } from 'src/app/modules/footer/footer.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-booking-result',
  templateUrl: './booking-result.component.html',
  styleUrls: ['./booking-result.component.scss']
})
export class BookingResultComponent implements OnInit {

  public language;

  public checkin;
  public checkout;
  public paese = '';

  public datainizio;
  public datafine;

  public results;

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  //search
  public inputSearch;
  public searchTable = false;
  public resultsPaese;
  public comuni;

  public adulti = null;
  public bambini = null;

  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, public dialog: MatDialog, private _snackBar: MatSnackBar) { 
    this.getComuni();
    this.getImmagini();
    this.results=null;

    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.checkin = params['checkin'];
      this.checkout = params['checkout'];
      var paese = params['paese'];
      this.adulti = params['adulti'];
      if(params['bambini']){
        this.bambini = params['bambini'];
      }
      
      console.log(this.bambini);
      console.log(this.paese);

      if(paese){
        this.paese = paese;
        this.inputSearch = paese;
        this.getMaps();
      }
      this.getResult();

      //riconverto la data di checkin in data visualizzabile sul calendario
      var offset = new Date().getTimezoneOffset()*60;
      let datain = (parseInt(this.checkin)+offset)*1000;
      this.datainizio = new Date(datain);

      if(this.checkout){
        //riconverto la data di checkout in data visualizzabile sul calendario
        var offset = new Date().getTimezoneOffset()*60;
        let dataout = (parseInt(this.checkout)+offset)*1000;
        this.datafine = new Date(dataout);
      }
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  search(){
    if(this.checkin && this.checkout){
      console.log(this.checkin);
      console.log(this.checkout);
      console.log(this.inputSearch);
      var paese = '';
      if(this.inputSearch){
        paese = this.inputSearch;
      }
    
      if(this.bambini==undefined || this.bambini==null || !this.bambini || this.bambini=='null'){
        this.bambini = 0;
      }

      if(this.adulti==undefined || this.adulti==null || !this.adulti || this.adulti=='mull'){
        this.adulti=1;
      }

      console.log(this.bambini);
      console.log(this.adulti);
      
      if(paese){
        this.router.navigate(['/booking-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+paese+'/'+this.adulti+'/'+this.bambini]);
      }else{
        this.router.navigate(['/booking-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
      }
    }else{
      var message = 'Inserisci la data di checkin e checkout';
      this.openSnackBar(message, 'ok');
    }
  }

  getResult(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/search?checkin='+this.checkin+'&checkout='+this.checkout+'&paese='+this.paese, options)
    .subscribe(
      res => {
        console.log(res);
        if(this.paese){
          self.getRaggio(res);
        }else{
          self.results = res;
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

  public imgviewall = {};
  getImmagini(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/viewallimg', options)
    .subscribe(
      res => {
        console.log(res);
        var imgviewall = res;

        console.log(imgviewall);
        for(let chiave in imgviewall){
          if(imgviewall[chiave].id_camera_immagine==null || imgviewall[chiave].id_camera_immagine==""){
            self.imgviewall[imgviewall[chiave].id_struttura_immagine] = imgviewall[chiave].url_immagine;
          }
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

  getComuni(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiviewallcomuni')
    .subscribe(
      res => {
        self.comuni = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  //funzione che mi ricerca i paesi in base all'input
  searchCity($event){
    var self = this;
    let output = [];
    if($event){
      $event = $event.toLowerCase();
      this.comuni.forEach(a => {
        var nome = a.Comune.toLowerCase();
        var n = nome.includes($event);
        if(n){
          output.push(a)
        }
      })

      self.resultsPaese = output;
      self.searchTable = true;
    }
  }

  selectComune(item){
    this.searchTable = false;
    this.inputSearch = item.Comune;
    console.log(item)
  }

  
  goModalmap(item){
    let dialogRef = this.dialog.open(ModalMapComponent, { 
      width: '600px',
      height: '400px',
      data: {
        item: item,
        language:this.language,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkin1 = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;

      
      //var dt = event.value.getTime()/1000;
      //this.checkin=dt;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkin=this.checkin-offset;
      this.datainizio=event.value;
      this.checkin = checkin1;
      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      //this.checkout = event.value.getTime()/1000;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkout=this.checkout-offset;

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkout = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;
      this.checkout = checkout;

      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }
  
  /*
  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      var dt = event.value.getTime()/1000;
      this.checkin=dt;
      var offset = new Date().getTimezoneOffset()*60;
      this.checkin=this.checkin-offset;
      this.datainizio=event.value;

      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      this.checkout = event.value.getTime()/1000;
      var offset = new Date().getTimezoneOffset()*60;
      this.checkout=this.checkout-offset;
      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }
  */

  filterDates = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if (timezone < 0)
     selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    else 
      selectedDay = d.setUTCHours(0,0,0,0);

    const today = (new Date()).setUTCHours(0,0,0,0);

    if (selectedDay >= today) {
      return true;
    }else
      return false;
  }

  filterDatesOut = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if(timezone < 0){
      selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    }
    else{
      selectedDay = d.setUTCHours(0,0,0,0);
    }

    if(this.checkin){
      if(selectedDay <= this.checkin*1000){
        return false;
      }
    }
    return true;
  }

  gotoBookingStructureDetail(id_struttura){
    console.log(id_struttura);
    this.router.navigate(['/booking-structure-detail/'+this.language+'/'+id_struttura+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  public coordsLat;
  public coordsLng;
  getMaps(){
    var url = 'https://maps.googleapis.com/maps/api/geocode/json?address='+this.paese+'&key=AIzaSyBOkIDQ6EFpThHj9ynz9K9ymXaMFxVngCI';
    
    var self = this;
    self.http.get(url)
    .subscribe(
      res => {
        console.log(res);
        var response = {} as  any; //oggetto di boolean
        response = res;
        console.log(response['results'][0].geometry.location);
        this.coordsLat = response['results'][0].geometry.location.lat;
        this.coordsLng= response['results'][0].geometry.location.lng;
      },
      err => {
        console.log(err); 
      }
    )    
  }

  getRaggio(res){
    console.log(res);
    //this.results = res;
    res.forEach(element => {
      var raggio = this.distanceInKmBetweenEarthCoordinates(element.lat_struttura+','+element.lng_struttura);
      console.log(raggio);
      element.raggio = raggio;
      console.log(element);
    });

    this.orderUp(res);
  }

  orderUp(res){
    console.log(res);
    var content = res;
    var listOrder = {};
    for(let i=0; i<content.length; i++ ){
      listOrder[i] = content[i].raggio;
    }
  
    var keysSorted = Object.keys(listOrder).sort(function(a,b){return listOrder[b]-listOrder[a]})
    var newReview = [];

    for(let i=0; i<keysSorted.length; i++){
      newReview[i] = content[keysSorted[i]];
    }
    content = newReview.reverse();
    console.log(content);
    this.results = content;
  }

  distanceInKmBetweenEarthCoordinates(destination) {

    //var lat1 = origin.split(",")[0];
    //var lon1 = origin.split(",")[1];
  
    var lat1 = this.coordsLat;
    var lon1 = this.coordsLng;

    var lat2 = destination.split(",")[0];
    var lon2 = destination.split(",")[1];
  
    var earthRadiusKm = 6371;
  
    var dLat = this.degreesToRadians(lat2-lat1);
    var dLon = this.degreesToRadians(lon2-lon1);
  
    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);
  
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return earthRadiusKm * c;
  }

  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }
}
