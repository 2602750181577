import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { ModalMessageComponent } from '../../../modules/struttura/modal-message/modal-message.component';

@Component({
  selector: 'app-booking-structure-detail',
  templateUrl: './booking-structure-detail.component.html',
  styleUrls: ['./booking-structure-detail.component.scss']
})
export class BookingStructureDetailComponent implements OnInit {
  
  objectKey = Object.keys; 
  
  public language;

  public checkin;
  public checkout;
  public paese = '';
  public datainizio;
  public datafine;


  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public id_struttura;
  public structure;
  public pensione;
  public imgCamera = {};
  public imgStruttura = {};
  
  //search
  public inputSearch;
  public searchTable = false;
  public resultsPaese;
  public comuni;

  public adulti;
  public bambini;

  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, public dialog: MatDialog) { 
    this.getComuni();
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.id_struttura = params['id_struttura'];
      this.checkin = params['checkin'];
      this.checkout = params['checkout'];
      this.adulti = params['adulti'];
      this.bambini = params['bambini'];

      console.log(this.id_struttura);
      this.getStructureDetail();
      this.getStrutturapensione();

      //riconverto la data di checkin in data visualizzabile sul calendario
      var offset = new Date().getTimezoneOffset()*60;
      let datain = (parseInt(this.checkin)+offset)*1000;
      this.datainizio = new Date(datain);

      //riconverto la data di checkout in data visualizzabile sul calendario
      var offset = new Date().getTimezoneOffset()*60;
      let dataout = (parseInt(this.checkout)+offset)*1000;
      this.datafine = new Date(dataout);
      
      if(!this.checkin && !this.checkout){
        this.goModalMessage();
      }else{
        this.getRoomAvailability();
      }
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  
  goModalMessage(){
    let dialogRef = this.dialog.open(ModalMessageComponent, { 
      width: '600px',
      height: '400px',
      data: {
        message: 'Inserisci i dati di checkin, checkout, adulti e bambini per scoprire le disponibilità delle stanze.',
        language:this.language,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  //le ultime 4 strutture inserite e validate
  getStructureDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturadetail?id_struttura='+this.id_struttura);

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturadetail?id_struttura='+this.id_struttura)
    .subscribe(
      res => {
        console.log(res);
        self.structure = res;
        console.log(self.structure);
        if(res[2]){
          var img = res[2];
          for(let i=0; i<img.length; i++){
            if(img[i].id_camera_immagine){
              var id = img[i].id_camera_immagine;
              self.imgCamera[id] = img[i].url_immagine;
            }else{
              var id = img[i].id_immagine;
              self.imgStruttura[id] = img[i].url_immagine;
            }
          }
        }
        console.log(self.imgCamera);
        self.getPrezzo();
      },
      err => {
        console.log(err);         
      }
    )
  }  

  getStrutturapensione(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturapensione?id_struttura='+this.id_struttura);
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturapensione?id_struttura='+this.id_struttura)
    .subscribe(
      res => {
        console.log(res);
        self.pensione = res;
      },
      err => {
        console.log(err);         
      }
    )
  }  

  public availability;
  getRoomAvailability(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/struttura/apistruttura/apiroomavailability?id_struttura='+this.id_struttura+'&checkin='+this.checkin+'&checkout='+this.checkout);
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiroomavailability?id_struttura='+this.id_struttura+'&checkin='+this.checkin+'&checkout='+this.checkout, options)
    .subscribe(
      res => {
        console.log(res);
        self.availability = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  public prezzoCamera;
  getPrezzo(){
    var camera = this.structure[1];
    for(let i=0; i<camera.length; i++){
      var prezzo = parseFloat(camera[i].prezzo_camera);
      if(i==0){
        this.prezzoCamera = prezzo;
      }else{
        if(prezzo<this.prezzoCamera){
          this.prezzoCamera = prezzo;
        }
      }
    }
  }

  getComuni(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiviewallcomuni')
    .subscribe(
      res => {
        self.comuni = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  //funzione che mi ricerca i paesi in base all'input
  searchCity($event){
    var self = this;
    let output = [];
    if($event){
      $event = $event.toLowerCase();
      this.comuni.forEach(a => {
        var nome = a.Comune.toLowerCase();
        var n = nome.includes($event);
        if(n){
          output.push(a)
        }
      })

      self.resultsPaese = output;
      self.searchTable = true;
    }
  }

  selectComune(item){
    this.searchTable = false;
    this.inputSearch = item.Comune;
    console.log(item)
  }

  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkin1 = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;

      
      //var dt = event.value.getTime()/1000;
      //this.checkin=dt;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkin=this.checkin-offset;
      this.datainizio=event.value;
      this.checkin = checkin1;
      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      //this.checkout = event.value.getTime()/1000;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkout=this.checkout-offset;

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkout = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;
      this.checkout = checkout;

      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }

  /*
  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      var dt = event.value.getTime()/1000;
      this.checkin=dt;
      var offset = new Date().getTimezoneOffset()*60;
      this.checkin=this.checkin-offset;
      this.datainizio=event.value;

      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      this.checkout = event.value.getTime()/1000;
      var offset = new Date().getTimezoneOffset()*60;
      this.checkout=this.checkout-offset;
      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }
  */
 
  filterDates = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if (timezone < 0)
     selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    else 
      selectedDay = d.setUTCHours(0,0,0,0);

    const today = (new Date()).setUTCHours(0,0,0,0);

    if (selectedDay >= today) {
      return true;
    }else
      return false;
  }

  filterDatesOut = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if(timezone < 0){
      selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    }
    else{
      selectedDay = d.setUTCHours(0,0,0,0);
    }

    if(this.checkin){
      if(selectedDay <= this.checkin*1000){
        return false;
      }
    }
    return true;
  }

  search(){
    if(this.checkin && this.checkout){
      console.log(this.checkin);
      console.log(this.checkout);
      console.log(this.inputSearch);

      if(this.bambini==undefined || this.bambini==null || !this.bambini || this.bambini=='null'){
        this.bambini = 0;
      }

      if(this.adulti==undefined || this.adulti==null || !this.adulti || this.adulti=='mull'){
        this.adulti=1;
      }

      this.router.navigate(['/booking-structure-detail/'+this.language+'/'+this.id_struttura+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
      
    }
  }

  gotoBookingRoomDetail(id_camera){
    console.log(id_camera);
    this.router.navigate(['/booking-room-detail/'+this.language+'/'+this.id_struttura+'/'+id_camera+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
  }
  
  openModalMap(){
    let dialogRef = this.dialog.open(ModalMapComponent,{
      width: '650px',
      height: '550px',
      disableClose: false,
      data:{
        item: this.structure[0][0]
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
