import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements OnInit {

  public message;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ModalMessageComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.message = data.message;
  }

  ngOnInit() {

  }

  close(){
    this.dialog.closeAll();
  }
}
