import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Http } from '@angular/http';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss']
})
export class DestinationComponent implements OnInit {

  public language;
  
  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public destinazioniList;
  public immaginiList = {};

  //mappa
  public mappa = false;
  public zoom = 6;
  public latMap = 41.9064878;
  public lonMap = 12.4536413;
  public coords = [];

  constructor(private route: ActivatedRoute, private router: Router, private http: Http, ) {
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
    });

    this.getResult();
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  public listMarker :any = { };

  markerClicked($event){
    console.log($event);
  }

  gotoUrl(id){
    this.router.navigate(['/destination-detail/'+this.language+'/'+id]);
  }

  getResult(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/destinazione/apidestinazione/apidestinazioneviewall')
    .subscribe(
      res => {
        console.log(res.json());
        var result = res.json();
        if(result){
          self.destinazioniList = result[0];
          
          self.destinazioniList.forEach(element => {
            console.log(element);
            var doc = {
              id: element.id_destinazione,
              zIndex : 1,
              lat: element.lat_destinazione,
              lng: element.lng_destinazione,
              iconUrl : {
                url: 'assets/marker/verde.svg',
                scaledSize: {
                  width: 40,
                  height: 40,
                },
              }
            }
            self.listMarker[ element.id_destinazione] = {
              id: element.id_destinazione,
              title: element.titolo_destinazione,
              citta: element.paese_destinazione,
            };
            self.coords.push(doc);
          });


          var img = result[1];
          for(let i=0; i<img.length; i++){
            if(img[i].id_destinazione_immagine){
              var id = img[i].id_destinazione_immagine;
              if(!self.immaginiList[id]){
                self.immaginiList[id] = img[i].url_immagine;
              }
            }
          }

          console.log(self.immaginiList);
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

  gotoDestinationDetail(destinationId){
    this.router.navigate(['/destination-detail/'+this.language+'/'+destinationId]);
  }
}
