import { Component, OnInit,ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import * as firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaObserver } from '@angular/flex-layout';

declare var stripe: any;
declare var elements: any

@Component({
  selector: 'app-booking-attivita-checkout',
  templateUrl: './booking-attivita-checkout.component.html',
  styleUrls: ['./booking-attivita-checkout.component.scss']
})
export class BookingAttivitaCheckoutComponent implements OnInit {

  @ViewChild('cardInfo', {static: false}) cardInfo: ElementRef;
  card: any;
  cardHandler = this.onChange.bind(this);

  @ViewChild('cardInfoMobile', {static: false}) cardInfoMobile: ElementRef;
  cardMobile: any;
  cardHandlerMobile = this.onChange.bind(this);

  public language;
  public userData;

  public id_reservation;
  public id_struttura;
  public id_camera;
  public id_att;
  public results;

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web'

  public error;
  
  public name;
  public surname;
  public email;
  public telefono;
  public idToken;
  public prezzo_totale;
  public stripe;
  public structure;
  public attivita;
  public type_pagamento_att = 0;
  
  constructor(private route: ActivatedRoute, private router: Router, public media: MediaObserver, private cd: ChangeDetectorRef, private _snackBar: MatSnackBar, private http: HttpClient, public dialog: MatDialog, public afAuth: AngularFireAuth, public db: AngularFireDatabase) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.id_reservation = params['id_reservation'];
      this.id_struttura = params['id_struttura'];
      this.id_camera = params['id_camera'];
      this.id_att = params['id_att'];
      console.log(this.id_reservation);
      console.log(this.id_struttura);
      console.log(this.id_camera);
      console.log(this.id_att);

      this.getStructureDetail();
      this.getAttivitaDetail();
      this.getStripe();
    });
  }

  ngOnInit() {
    window.scroll(0,0);
    var self = this;
    this.afAuth.auth.onAuthStateChanged( user => {
      if(user){
        console.log(user);
        self.db.object('/users/'+user.uid).valueChanges().subscribe(action=>{
          console.log(action);
          if(action){
            self.userData = action;
            self.name = self.userData.name;
            self.surname = self.userData.surname;
            self.email = self.userData.email;
            self.userData.uid = user.uid;
            console.log(self.userData);
          }
        });      

        firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
          self.idToken = idToken;
        });
      }
    });
  }
  
    
  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  public mobile:any;
  ngAfterViewInit() {
    if(this.media.isActive('xl')){
      this.mobile = false;
    }else if(this.media.isActive('lg')){
      this.mobile = false;
    }else if(this.media.isActive('md')){
      this.mobile = false;
    }else if(this.media.isActive('sm')){
      this.mobile = true;
    }else if(this.media.isActive('xs')){
      this.mobile = true;
    }

    const style = {
      base: {
        lineHeight: '24px',
        fontSize: '16px',
        '::placeholder': {
          color: '#484848'
        }
      }
    };
  
    if(this.mobile==false){
      this.card = elements.create('card', { style });
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);  
    }else{
      this.cardMobile = elements.create('card', { style });
      this.cardMobile.mount(this.cardInfoMobile.nativeElement);
      this.cardMobile.addEventListener('change', this.cardHandlerMobile);  
    }
  }

  ngOnDestroy() {
    if(this.mobile==false){
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    } else {
      this.cardMobile.removeEventListener('change', this.cardHandler);
      this.cardMobile.destroy();
    }
  }

  //le ultime 4 strutture inserite e validate
  getStructureDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturadetail?id_struttura='+this.id_struttura)
    .subscribe(
      res => {
        console.log(res);
        self.structure = res;
        console.log(self.structure);
      },
      err => {
        console.log(err);         
      }
    )
  }

  //le ultime 4 strutture inserite e validate
  getAttivitaDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/attivita/apiattivita/apiattivitadetail?id_att='+this.id_att)
    .subscribe(
      res => {
        console.log(res);
        self.attivita = res;
        if(self.attivita){
          self.prezzo_totale = res['costo_att'];
          self.type_pagamento_att = res['type_pagamento_att'];
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

  getStripe(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistripe?id_struttura='+this.id_struttura)
    .subscribe(
      res => {
        console.log(res);
        self.stripe = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  public spinnerShow = false;
  paga(){
    var self = this;
    if(1){
      if(1){
        this.spinnerShow=true;
        stripe.createPaymentMethod({
          type: 'card',
          card: self.card,
          billing_details: {
            name: self.name+' '+self.surname,
          }
        }).then(function(result){
          console.log(result);
          if(result.paymentMethod){
            var paymentMethod = result.paymentMethod.id;

            const headers = new HttpHeaders({
              'Authorization': self.idToken,
              'Content-Type': 'application/json',
            });
      
            var options = {
              headers: headers,
            };

            var body = {
              customerId:self.userData.customerId,
              merchantId:self.stripe.merchant_id_stripe,
              paymentMethod: paymentMethod,
              amount:self.prezzo_totale,
              fee:self.stripe.fee_stripe,
              currency:"eur",
              description:self.userData.name+' '+self.userData.surname+' '+self.structure[0][0].nome_struttura+' - '+self.attivita.nome_att
            }

            //api dove ti passo il payment method
            self.http.post('https://api.composingstudio.com/v1/touringflat/stripe/payment-intent/', body, options)
            .subscribe(
              res => {
                console.log(res);
                var client_secret = res['client_secret'];

                self.confirm(paymentMethod, client_secret);
                
              },
              err => {
                console.log(err);
                self.spinnerShow = false;
                self.error = 'Si è verificato un errore.';
              }
            )   
          }else{
            self.spinnerShow = false;
            self.error = result.error.message;
            //header = this.label['register'].attenzione[this.language];
            //button = this.label['register'].riprova[this.language];
            //message = 'Inserisci tutte le informazioni richieste.';
            //this.supportService.presentAlert(header, message, button);
          }
        })
      }else{
        //pagamento senza carta di credito

        //pagamento_prenotazione = 1 pagamento in struttura
        //pagamento_prenotazione = 2 pagamento tramite portale
        this.setPrenotazione(1);
      }
    }else{
      this.error = 'Devi accettare il regolamento della struttura';
     // this.openSnackBar(message, 'OK');
    }
  }

  async confirm(paymentMethod, client_secret){ 
    var self = this;
    let payment = await stripe.confirmCardPayment(client_secret,{
      payment_method: paymentMethod
    });

    console.log(payment);
    if(payment.error) {
      this.error = payment.error;
    }else{
      self.setPrenotazione2(2);
    }
  }

  //invio i dati della prenotazione
  setPrenotazione(pagamento_prenotazione){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    var utente = this.userData.name+' '+this.userData.surname+' '+this.userData.email;

    self.http.get(this.urlbackend+'/index.php/attivita/apiattivita/apisetprenotazione?id_att='+this.id_att+'&prezzo='+this.prezzo_totale+'&id_utentefirebase='+this.userData.uid+'&utente='+utente+'&pagamento_prenotazione='+pagamento_prenotazione+'&idprenotazione='+this.id_reservation, options)
    .subscribe(
      res => {
        console.log(res);
        var message = 'Prenotazione effetuata con successo.';
        self.openSnackBar(message, 'OK');
        self.router.navigate(['/home/'+this.language]);
      },
      err => {
        console.log(err);       
        self.error = 'Si è verificato un errore';
      }
    )
  }

  
  pagaMobile(){
    var self = this;
    if(1){
      if(1){
        this.spinnerShow=true;
        stripe.createPaymentMethod({
          type: 'card',
          card: self.cardMobile,
          billing_details: {
            name: self.name+' '+self.surname,
          }
        }).then(function(result){
          console.log(result);
          if(result.paymentMethod){
            var paymentMethod = result.paymentMethod.id;

            const headers = new HttpHeaders({
              'Authorization': self.idToken,
              'Content-Type': 'application/json',
            });
      
            var options = {
              headers: headers,
            };

            var body = {
              customerId:self.userData.customerId,
              merchantId:self.stripe.merchant_id_stripe,
              paymentMethod:paymentMethod,
              amount:self.prezzo_totale,
              fee:self.stripe.fee_stripe,
              currency:"eur",
              description:self.userData.name+' '+self.userData.surname+' '+self.structure[0][0].nome_struttura+' - '+self.attivita.nome_att
            }

            //api dove ti passo il payment method
            self.http.post('https://api.composingstudio.com/v1/touringflat/stripe/payment-intent/', body, options)
            .subscribe(
              res => {
                console.log(res);
                var client_secret = res['client_secret'];
                self.confirm(paymentMethod, client_secret);
              },
              err => {
                console.log(err);
                self.spinnerShow = false;
                self.error = 'Si è verificato un errore.';
              }
            )   
          }else{
            self.spinnerShow = false;
            self.error = result.error.message;
            //header = this.label['register'].attenzione[this.language];
            //button = this.label['register'].riprova[this.language];
            //message = 'Inserisci tutte le informazioni richieste.';
            //this.supportService.presentAlert(header, message, button);
          }
        })
      }else{
        //pagamento senza carta di credito

        //pagamento_prenotazione = 1 pagamento in struttura
        //pagamento_prenotazione = 2 pagamento tramite portale
        this.setPrenotazione(1);
      }
    }else{
      this.error = 'Devi accettare il regolamento della struttura';
     // this.openSnackBar(message, 'OK');
    }
  }

  setPrenotazione2(pagamento_prenotazione){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    var utente = this.userData.name+' '+this.userData.surname+' '+this.userData.email;

    self.http.get(this.urlbackend+'/index.php/attivita/apiattivita/apisetprenotazione?id_att='+this.id_att+'&prezzo='+this.prezzo_totale+'&id_utentefirebase='+this.userData.uid+'&utente='+utente+'&pagamento_prenotazione='+pagamento_prenotazione+'&idprenotazione='+this.id_reservation, options)
    .subscribe(
      res => {
        console.log(res);
        var message = 'Prenotazione effetuata con successo.';
        self.openSnackBar(message, 'OK');
        self.router.navigate(['/home/'+this.language]);
      },
      err => {
        console.log(err);       
        self.error = 'Si è verificato un errore';
      }
    )
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
}