import { Injectable } from '@angular/core';
import { GoogleObj } from '../../models/solution.model';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class GoogletranslateService {
  url = "https://translation.googleapis.com/language/translate/v2?key=";
  key = "AIzaSyA7Y1solGde6AGyD_Fb48HnD5lg46QGO5U";
  constructor(private http: HttpClient) { }

  translate(obj: GoogleObj) {
    return this.http.post(this.url + this.key, obj);
  }
}
