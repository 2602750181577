import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';

@Component({
  selector: 'app-booking-attivita',
  templateUrl: './booking-attivita.component.html',
  styleUrls: ['./booking-attivita.component.scss']
})
export class BookingAttivitaComponent implements OnInit {

  public language;
  public userData;

  public id_reservation;
  public id_camera;
  public results;

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, public dialog: MatDialog,) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.id_reservation = params['id_reservation'];
      this.id_camera = params['id_camera'];
      console.log(this.id_reservation);

      this.getResult();
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  getResult(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/attivita/apiattivita/apiattivitaviewall?idcamera='+this.id_camera, options)
    .subscribe(
      res => {
        console.log(res);
        self.results = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  gotoCheckout(id_struttura, idatt){
    this.router.navigate(['/booking-attivita-checkout/'+this.language+'/'+this.id_reservation+'/'+id_struttura+'/'+this.id_camera+'/'+idatt]);
  }
}
