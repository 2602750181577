import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-quest',
  templateUrl: './quest.component.html',
  styleUrls: ['./quest.component.scss']
})
export class QuestComponent implements OnInit {

  public urlbackend = 'https://managers.touringflat.com/web';

  public struttureapi;
  public results;

  public destinazioniList;
  public immaginiList = {};

  //mappa
  public mappa = false;
  public zoom = 6;
  public latMap = 41.9064878;
  public lonMap = 12.4536413;
  public coords = [];

  public type = 1;

  public domanda1 = {
    1:[3,6,5,2,1,8,7,4],
    2:[1,5,7,6,4,2,3,8],
    3:[8,6,4,7,5,3,2,1],
    4:[6,1,2,3,8,5,7,4],
  };

  public domanda2 = {
    1:[7,1,4,3,8,2,6,5],
    2:[4,7,2,3,6,5,8,1],
    3:[4,7,5,8,1,3,2,6],
    4:[2,3,7,5,4,8,1,6],
  };

  public domanda3 = {
    1:[5,8,4,2,1,3,6,7],
    2:[8,5,3,7,4,1,2,6],
    3:[1,4,7,6,5,8,2,3],
    4:[5,1,4,3,8,6,7,2],
  };

  public domanda4 = {
    1:[2,4,3,5,1,8,6,7],
    2:[7,6,2,8,3,1,5,4],
    3:[8,5,3,7,6,1,4,2],
    4:[2,4,6,1,7,8,3,5],
  };

  public domanda5 = {
    1:[8,5,4,6,7,2,1,3],
    2:[3,8,4,2,1,6,5,7],
    3:[5,3,1,6,7,2,8,4],
    4:[1,4,7,3,2,8,6,5],
  };

  public domanda6 = {
    1:[8,7,2,5,4,1,3,6],
    2:[1,6,3,5,4,7,8,2],
    3:[7,6,3,5,8,2,1,4],
    4:[2,1,8,4,3,7,5,6],
  };

  public domanda7 = {
    1:[1,6,4,2,5,7,8,3],
    2:[8,6,3,7,4,1,2,5],
    3:[5,1,6,8,7,2,4,3],
    4:[1,4,8,3,2,7,6,5],
  };

  public domanda8 = {
    1:[7,5,4,8,6,1,2,3],
    2:[2,4,5,1,3,7,8,6],
    3:[4,3,2,7,6,8,5,1],
    4:[5,6,8,4,3,1,2,7],
  };

  public domanda9 = {
    1:[2,7,1,3,4,5,6,8],
    2:[4,5,8,7,3,6,2,1],
    3:[3,4,2,1,6,5,8,7],
    4:[7,3,6,8,5,1,4,2],
  };

  public domanda10 = {
    1:[2,4,5,2,1,7,8,6],
    2:[7,2,5,6,8,1,4,3],
    3:[3,8,7,1,2,5,4,6],
    4:[6,3,2,8,7,5,1,4],
  };

  public risposta1 = [0,0,0,0,0,0,0,0,0,0];
  public risposta2 = [0,0,0,0,0,0,0,0,0,0];
  public risposta3 = [0,0,0,0,0,0,0,0,0,0];
  public risposta4 = [0,0,0,0,0,0,0,0,0,0];
  public risposta5 = [0,0,0,0,0,0,0,0,0,0];
  public risposta6 = [0,0,0,0,0,0,0,0,0,0];
  public risposta7 = [0,0,0,0,0,0,0,0,0,0];
  public risposta8 = [0,0,0,0,0,0,0,0,0,0];
  public risposta9 = [0,0,0,0,0,0,0,0,0,0];
  public risposta10 =[0,0,0,0,0,0,0,0,0,0];
  
  public userData;

  public language;

  constructor(private http: HttpClient, private route: ActivatedRoute, public afAuth: AngularFireAuth, public db: AngularFireDatabase, private router: Router, private _snackBar: MatSnackBar,) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
    });
  }

  ngOnInit() {
    window.scroll(0,0);
    var self = this;
    this.afAuth.auth.onAuthStateChanged( user => {
      console.log(user);
      if(user){
        self.db.object('/users/'+user.uid).valueChanges().subscribe(action=>{
          console.log(action);
          if(action){
            self.userData = action;
            self.userData.uid = user.uid;
          }
        });      
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  gotoReload(){
    window.scroll(0,0);
    this.type=1;
  }

  gotoSave(){
    var self = this;
    if(this.userData){
      this.db.object('/users/'+this.userData.uid).update({
        questionario: self.demoradarChartData,
      });

      this.openSnackBar('Informazioni salvate!', 'OK');

    }else{
      this.router.navigate(['/access/it/login/']);
    }
  }

  radioChange1($event){
    console.log($event);
    var number = $event;
    this.risposta1 = this.domanda1[number];
    this.type=2;
  }

  radioChange2($event){
    console.log($event);
    var number = $event;
    this.risposta2 = this.domanda2[number];
    this.type=3;
  }

  radioChange3($event){
    console.log($event);
    var number = $event;
    this.risposta3 = this.domanda3[number];
    this.type=4;
  }

  radioChange4($event){
    console.log($event);
    var number = $event;
    this.risposta4 = this.domanda4[number];
    this.type=5;
  }

  radioChange5($event){
    console.log($event);
    var number = $event;
    this.risposta5 = this.domanda5[number];
    this.type=6;
  }

  radioChange6($event){
    console.log($event);
    var number = $event;
    this.risposta6 = this.domanda6[number];
    this.type=7;
  }

  radioChange7($event){
    console.log($event);
    var number = $event;
    this.risposta7 = this.domanda7[number];
    this.type=8;
  }

  radioChange8($event){
    console.log($event);
    var number = $event;
    this.risposta8 = this.domanda8[number];
    this.type=9;
  }

  radioChange9($event){
    console.log($event);
    var number = $event;
    this.risposta9 = this.domanda9[number];
    this.type=10;
  }

  radioChange10($event){
    console.log($event);
    var number = $event;
    this.risposta10 = this.domanda10[number];
    this.calcola();
    this.type=0;
  }

  /*
  radioChange1($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta1 = this.domanda1[number];
  }

  radioChange2($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta2 = this.domanda2[number];
  }

  radioChange3($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta3 = this.domanda3[number];
  }

  radioChange4($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta4 = this.domanda4[number];
  }

  radioChange5($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta5 = this.domanda5[number];
  }

  radioChange6($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta6 = this.domanda6[number];
  }

  radioChange7($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta7 = this.domanda7[number];
  }

  radioChange8($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta8 = this.domanda8[number];
  }

  radioChange9($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta9 = this.domanda9[number];
  }

  radioChange10($event){
    console.log($event.value);
    var number = $event.value;
    this.risposta10 = this.domanda10[number];
    console.log(this.risposta10);
  }
  */

  public output = [0,0,0,0,0,0,0,0];

  calcola(){
    this.output[0] = this.risposta1[0]+this.risposta2[0]+this.risposta3[0]+this.risposta4[0]+this.risposta5[0]+this.risposta6[0]+this.risposta7[0]+this.risposta8[0]+this.risposta9[0]+this.risposta10[0];
    this.output[1] = this.risposta1[1]+this.risposta2[1]+this.risposta3[1]+this.risposta4[1]+this.risposta5[1]+this.risposta6[1]+this.risposta7[1]+this.risposta8[1]+this.risposta9[1]+this.risposta10[1];
    this.output[2] = this.risposta1[2]+this.risposta2[2]+this.risposta3[2]+this.risposta4[2]+this.risposta5[2]+this.risposta6[2]+this.risposta7[2]+this.risposta8[2]+this.risposta9[2]+this.risposta10[2];
    this.output[3] = this.risposta1[3]+this.risposta2[3]+this.risposta3[3]+this.risposta4[3]+this.risposta5[3]+this.risposta6[3]+this.risposta7[3]+this.risposta8[3]+this.risposta9[3]+this.risposta10[3];
    this.output[4] = this.risposta1[4]+this.risposta2[4]+this.risposta3[4]+this.risposta4[4]+this.risposta5[4]+this.risposta6[4]+this.risposta7[4]+this.risposta8[4]+this.risposta9[4]+this.risposta10[4];
    this.output[5] = this.risposta1[5]+this.risposta2[5]+this.risposta3[5]+this.risposta4[5]+this.risposta5[5]+this.risposta6[5]+this.risposta7[5]+this.risposta8[5]+this.risposta9[5]+this.risposta10[5];
    this.output[6] = this.risposta1[6]+this.risposta2[6]+this.risposta3[6]+this.risposta4[6]+this.risposta5[6]+this.risposta6[6]+this.risposta7[6]+this.risposta8[6]+this.risposta9[6]+this.risposta10[6];
    this.output[7] = this.risposta1[7]+this.risposta2[7]+this.risposta3[7]+this.risposta4[7]+this.risposta5[7]+this.risposta6[7]+this.risposta7[7]+this.risposta8[7]+this.risposta9[7]+this.risposta10[7];
    console.log(this.output);
    this.demoradarChartData[0].data = this.output;

    var num = 0;
    if(this.output[0]>=this.output[1] && this.output[0]>=this.output[2] && this.output[0]>=this.output[3] && this.output[0]>=this.output[4] && this.output[0]>=this.output[5] && this.output[0]>=this.output[6] && this.output[0]>=this.output[7]){
      num=0;
      this.demoradarChartData[0].label = 'ESCURSIONISTICO';
    }else if(this.output[1]>=this.output[0] && this.output[1]>=this.output[2] && this.output[1]>=this.output[3] && this.output[1]>=this.output[4] && this.output[1]>=this.output[5] && this.output[1]>=this.output[6] && this.output[1]>=this.output[7]){
      num=1;
      this.demoradarChartData[0].label = 'STORICO';
    }else if(this.output[2]>=this.output[0] && this.output[2]>=this.output[1] && this.output[2]>=this.output[3] && this.output[2]>=this.output[4] && this.output[2]>=this.output[5] && this.output[2]>=this.output[6] && this.output[2]>=this.output[7]){
      num=2;
      this.demoradarChartData[0].label = 'GASTRONOMICO';
    }else if(this.output[3]>=this.output[0] && this.output[3]>=this.output[1] && this.output[3]>=this.output[2] && this.output[3]>=this.output[4] && this.output[3]>=this.output[5] && this.output[3]>=this.output[6] && this.output[3]>=this.output[7]){
      num=3;
      this.demoradarChartData[0].label = 'NATURALISTICO';
    }else if(this.output[4]>=this.output[0] && this.output[4]>=this.output[1] && this.output[4]>=this.output[2] && this.output[4]>=this.output[3] && this.output[4]>=this.output[5] && this.output[4]>=this.output[6] && this.output[4]>=this.output[7]){
      num=4;
      this.demoradarChartData[0].label = 'AVVENTUROSO';
    }else if(this.output[5]>=this.output[0] && this.output[5]>=this.output[1] && this.output[5]>=this.output[2] && this.output[5]>=this.output[3] && this.output[5]>=this.output[4] && this.output[5]>=this.output[6] && this.output[5]>=this.output[7]){
      num=5;
      this.demoradarChartData[0].label = 'RILASSANTE';
    }else if(this.output[6]>=this.output[0] && this.output[6]>=this.output[1] && this.output[6]>=this.output[2] && this.output[6]>=this.output[3] && this.output[6]>=this.output[4] && this.output[6]>=this.output[5] && this.output[6]>=this.output[7]){
      num=6;
      this.demoradarChartData[0].label = 'ROMANTICO';
    }else if(this.output[7]>=this.output[0] && this.output[7]>=this.output[1] && this.output[7]>=this.output[2] && this.output[7]>=this.output[3] && this.output[7]>=this.output[4] && this.output[7]>=this.output[5] && this.output[7]>=this.output[6]){
      num=7;
      this.demoradarChartData[0].label = 'SPIRITUALE';
    }

    this.getStrutture(num);
    this.getResult(num);
    this.getDestination(num);
    this.getImmagini();
  }

  // Radar
  public demoradarChartLabels:string[] = ['ESCURSIONISTICO', 'STORICO','GASTRONOMICO','NATURALISTICO', 'AVVENTUROSO', 'RILASSANTE', 'ROMANTICO','SPIRITUALE'];
 
  public demoradarChartData:any = [
    {data: [0, 0, 0, 0, 0, 0, 0, 0], label: 'Risultato'},
  ];

  public radarChartType:string = 'radar';
 
  // events
  public chartClicked(e:any):void {
    console.log(e);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  }
  
  //le ultime 4 strutture inserite e validate
  public imamagini = {};
  getStrutture(num){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturaviewalltipo?tipo='+num, options)
    .subscribe(
      res => {
        console.log(res);
        if(res){
          self.struttureapi = res[0];
          var imamagini = res[1];
          for(let i=0; i<imamagini.length; i++ ){
            if(!self.imamagini[imamagini[i].id_struttura_immagine]){
              self.imamagini[imamagini[i].id_struttura_immagine] = self.urlbackend+'/'+imamagini[i].url_immagine;
            }
          }
        }
        console.log(self.struttureapi);

        self.struttureapi.forEach(element => {
          console.log(element);
          var doc = {
            id: element.id_struttura,
            zIndex : 1,
            lat: element.lat_struttura,
            lng: element.lng_struttura,
            type:'struttura',
            iconUrl : {
              url: 'assets/marker/blue.svg',
              scaledSize: {
                width: 40,
                height: 40,
              },
            }
          }

          self.listMarker[element.id_struttura] = {
            id: element.id_struttura,
            type:'struttura',
            title: element.nome_struttura,
            citta: element.citta_struttura,
          };

          self.coords.push(doc);
          console.log(self.coords);
        });
      },
      err => {
        console.log(err);         
      }
    )
  }

  getResult(num){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/searchtipo?tipo='+num, options)
    .subscribe(
      res => {
        console.log(res);
        self.results = res;

        self.results.forEach(element => {
          console.log(element);
          var doc = {
            id: element.id_servizio,
            zIndex : 1,
            lat: element.lat_servizio,
            lng: element.lng_servizio,
            type:'servizio',
            iconUrl : {
              url: 'assets/marker/arancio.svg',
              scaledSize: {
                width: 40,
                height: 40,
              },
            }
          }

          self.listMarker[element.id_servizio] = {
            id: element.id_servizio,
            type:'servizio',
            title: element.nome_servizio,
            citta: element.citta_servizio,
          };

          self.coords.push(doc);
          console.log(self.coords);
        });
      },
      err => {
        console.log(err);         
      }
    )
  }

  public listMarker:any = { };
  markerClicked($event){
    console.log($event);
  }
  
  gotoUrl(id, type){
    console.log(type);
    if(type=='struttura'){
      this.router.navigate(['/booking-structure-detail/'+this.language+'/'+id]);
    }else if(type=='servizio'){
      this.router.navigate(['/booking-service-detail/'+this.language+'/'+id]);
    }else if(type='destinazione'){
      this.router.navigate(['/destination-detail/'+this.language+'/'+id]);
    }
  }
  
  public imgviewall = {};
  getImmagini(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/viewallimg', options)
    .subscribe(
      res => {
        console.log(res);
        var imgviewall = res;
        
        for(let chiave in imgviewall){
          if(imgviewall[chiave].id_serviziodetail_immagine==null || imgviewall[chiave].id_serviziodetail_immagine==""){
            console.log(imgviewall[chiave].id_servizio_immagine);
            self.imgviewall[imgviewall[chiave].id_servizio_immagine] = imgviewall[chiave].url_immagine;
          }
        }
        console.log(imgviewall);
      },
      err => {
        console.log(err);         
      }
    )
  }

  getDestination(num){
    var self = this;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/destinazione/apidestinazione/searchtipo?tipo='+num);

    self.http.get(this.urlbackend+'/index.php/destinazione/apidestinazione/searchtipo?tipo='+num, options)
    .subscribe(
      res => {
        console.log(res);
        var result = res;
        if(result){
          self.destinazioniList = result[0];
          
          self.destinazioniList.forEach(element => {
            console.log(element);
            var doc = {
              id: element.id_destinazione,
              zIndex : 1,
              lat: element.lat_destinazione,
              lng: element.lng_destinazione,
              type:'destinazione',
              iconUrl : {
                url: 'assets/marker/verde.svg',
                scaledSize: {
                  width: 40,
                  height: 40,
                },
              }
            }

            self.listMarker[element.id_destinazione] = {
              id: element.id_destinazione,
              type:'destinazione',
              title: element.titolo_destinazione,
              citta: element.sottotitolo_destinazione,
            };
            
            self.coords.push(doc);
          });


          var img = result[1];
          for(let i=0; i<img.length; i++){
            if(img[i].id_destinazione_immagine){
              console.log(img[i]);
              var id = img[i].id_destinazione_immagine;
              self.immaginiList[id] = img[i].url_immagine;
            }
          }

          console.log(self.immaginiList);
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

  gotoBookingStructureDetail(id_struttura){
    console.log(id_struttura);
    var checkin = Math.floor((Date.now()/1000)+86400);
    var checkout = Math.floor((Date.now()/1000)+86400);
    this.router.navigate(['/booking-structure-detail/'+this.language+'/'+id_struttura+'/'+'/'+checkin+'/'+checkout+'/1/0']);
  }

  gotoBookingServiceDetail(id_servizio){
    console.log(id_servizio);
    var checkin = Math.floor((Date.now()/1000)+86400);
    var checkout = Math.floor((Date.now()/1000)+86400);
    console.log(Date.now());
    this.router.navigate(['/booking-service-detail/'+this.language+'/'+id_servizio+'/'+checkin+'/'+checkout+'/1/0']);
  }

  gotoDestinationDetail(destinationId){
    this.router.navigate(['/destination-detail/'+this.language+'/'+destinationId]);
  }
}