import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFireAuth } from '@angular/fire/auth';
import { OrderService } from '../../../service/order/order.service';

@Component({
  selector: 'app-booking-room-detail',
  templateUrl: './booking-room-detail.component.html',
  styleUrls: ['./booking-room-detail.component.scss']
})
export class BookingRoomDetailComponent implements OnInit {

  public language;
  public id_struttura;
  public id_camera;
  
  public checkin;
  public checkout;
  public paese = '';
  public datainizio;
  public datafine;

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';


  public structure;
  public room;

  //search
  public inputSearch;
  public searchTable = false;
  public resultsPaese;
  public comuni;
  public numgiorni = 0;

  public userData;
  public adulti;
  public bambini;

  public prezzo_totale;
  public pezzostagione;

  constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute, public orderService: OrderService, public dialog: MatDialog, public afAuth: AngularFireAuth, public db: AngularFireDatabase,) { 
    this.getComuni();
    this.orderService.setOrder(null);
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.id_struttura = params['id_struttura'];
      this.id_camera = params['id_camera'];
      this.checkin = params['checkin'];
      this.checkout = params['checkout'];
      this.adulti = params['adulti'];
      this.bambini = params['bambini'];

      this.numgiorni = (this.checkout-this.checkin)/86400;

      console.log(this.id_struttura);
      console.log(this.id_camera);
      this.getRoomDetail();
      this.getPrice();
      this.getStructureDetail();
      this.getPrezzostagione();

      //riconverto la data di checkin in data visualizzabile sul calendario
      var offset = new Date().getTimezoneOffset()*60;
      let datain = (parseInt(this.checkin)+offset)*1000;
      this.datainizio = new Date(datain);

      //riconverto la data di checkout in data visualizzabile sul calendario
      var offset = new Date().getTimezoneOffset()*60;
      let dataout = (parseInt(this.checkout)+offset)*1000;
      this.datafine = new Date(dataout);
    });
  }

  ngOnInit() {
    window.scroll(0,0);
    var self = this;
    this.afAuth.auth.onAuthStateChanged( user => {
      if(user){
        console.log(user);
        self.db.object('/users/'+user.uid).valueChanges().subscribe(action=>{
          console.log(action);
          self.userData = action;
        });
      }
    });
  }

  getPrezzostagione(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/struttura/apistruttura/apiprezzostagione?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera+'&checkin='+this.checkin+'&checkout='+this.checkout);
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiprezzostagione?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera+'&checkin='+this.checkin+'&checkout='+this.checkout)
    .subscribe(
      res => {
        console.log(res);
        self.pezzostagione = res;
      },
      err => {
        console.log(err);         
      }
    )
  }  

  //le ultime 4 strutture inserite e validate
  getStructureDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturadetail?id_struttura='+this.id_struttura)
    .subscribe(
      res => {
        console.log(res);
        self.structure = res;
        console.log(self.structure);
      },
      err => {
        console.log(err);         
      }
    )
  }
    
  getRoomDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiroomdetail?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera)
    .subscribe(
      res => {
        console.log(res);
        self.room = res;
        console.log(self.room);
      },
      err => {
        console.log(err);         
      }
    )
  }

  getPrice(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apirommprice?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera+'&checkin='+this.checkin+'&checkout='+this.checkout)
    .subscribe(
      res => {
        console.log(res);
        self.prezzo_totale = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  search(){
    if(this.checkin && this.checkout){
      console.log(this.checkin);
      console.log(this.checkout);
      console.log(this.inputSearch);
      var paese = '';
      if(this.inputSearch){
        paese = this.inputSearch;
      }

      if(paese){
        this.router.navigate(['/booking-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+paese+'/'+this.adulti+'/'+this.bambini]);
      }else{
        this.router.navigate(['/booking-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
      }
    }
  }

  getComuni(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiviewallcomuni')
    .subscribe(
      res => {
        self.comuni = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  //funzione che mi ricerca i paesi in base all'input
  searchCity($event){
    var self = this;
    let output = [];
    if($event){
      $event = $event.toLowerCase();
      this.comuni.forEach(a => {
        var nome = a.Comune.toLowerCase();
        var n = nome.includes($event);
        if(n){
          output.push(a)
        }
      })

      self.resultsPaese = output;
      self.searchTable = true;
    }
  }

  selectComune(item){
    this.searchTable = false;
    this.inputSearch = item.Comune;
    console.log(item)
  }

  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      var dt = event.value.getTime()/1000;
      this.checkin=dt;
      var offset = new Date().getTimezoneOffset()*60;
      this.checkin=this.checkin-offset;
      this.datainizio=event.value;

      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      this.checkout = event.value.getTime()/1000;
      var offset = new Date().getTimezoneOffset()*60;
      this.checkout=this.checkout-offset;
      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }

  filterDates = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if (timezone < 0)
     selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    else 
      selectedDay = d.setUTCHours(0,0,0,0);

    const today = (new Date()).setUTCHours(0,0,0,0);

    if (selectedDay >= today) {
      return true;
    }else
      return false;
  }

  filterDatesOut = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if(timezone < 0){
      selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    }
    else{
      selectedDay = d.setUTCHours(0,0,0,0);
    }

    if(this.checkin){
      if(selectedDay <= this.checkin*1000){
        return false;
      }
    }
    return true;
  }

  openModalMap(){
    let dialogRef = this.dialog.open(ModalMapComponent,{
      width: '650px',
      height: '550px',
      disableClose: false,
      data:{
        item: this.structure[0][0]
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  gotoBookingCheckout(){
    if(this.userData){
      this.router.navigate(['/booking-checkout/'+this.language+'/'+this.id_struttura+'/'+this.id_camera+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
    }else{

      var order = {
        type:'struttura',
        id_struttura : this.id_struttura,
        id_camera: this.id_camera,
        checkin: this.checkin,
        checkout: this.checkout,
        adulti: this.adulti,
        bambini: this.bambini,
      }

      this.orderService.setOrder(order);
      this.router.navigate(['/access/'+this.language+'/login']);
    }
  }
}
