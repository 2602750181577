import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-regolamentoservizio',
  templateUrl: './modal-regolamentoservizio.component.html',
  styleUrls: ['./modal-regolamentoservizio.component.scss']
})
export class ModalRegolamentoservizioComponent implements OnInit {

  public servizio;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ModalRegolamentoservizioComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.servizio = data.structure;
    console.log(this.servizio);
  }

  ngOnInit() {

  }
}
