import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth, public http: HttpClient, public db: AngularFireDatabase) { 

  }

  signupUser(email: string, password: string, name: string, surname: string): Promise<any> {
    var self = this;
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
    .then((result) => {
      console.log(result);

      var self = this;
      //inserisco il nuovo utente
      //verifico se l'utente è autenticato e se è proprietario
      self.afAuth.auth.currentUser.getIdToken(true).then(function(idToken) {

        var uid = result.user.uid;
        console.log(idToken);

        self.http.post('https://api.composingstudio.com/v1/touringflat/stripe/customer/', {
          token: idToken,
          name: name,
          surname: surname,
        })
        .subscribe(
          res => {
            console.log(res);
            var docData = {
              name: name,
              surname: surname,  
              email : email,
              customerId: res['customer_id'],
            };  

            firebase.database().ref('/users/'+uid).set(docData);
          },
          err => {
            console.log(err);
          }
        )
      })
    })
  }

  loginUser(email: string, password: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }
  
  logoutUser(): Promise<void> {
    return this.afAuth.auth.signOut();
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
