import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AccessComponent } from './pages/access/access.component';
import { ServicesComponent } from './pages/services/services.component';
import { InformationComponent } from './pages/information/information.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DestinationComponent } from './pages/destination/destination/destination.component';
import { DestinationDetailComponent } from './pages/destination/destination-detail/destination-detail.component';
import { BookingStructureComponent } from './pages/booking/booking-structure/booking-structure.component';
import { BookingCheckoutComponent } from './pages/booking/booking-checkout/booking-checkout.component';
import { BookingResultComponent } from './pages/booking/booking-result/booking-result.component';
import { BookingStructureDetailComponent } from './pages/booking/booking-structure-detail/booking-structure-detail.component';
import { BookingRoomDetailComponent } from './pages/booking/booking-room-detail/booking-room-detail.component';
import { UserUpdateComponent } from './pages/user/user-update/user-update.component';
import { UserReservationComponent } from './pages/user/user-reservation/user-reservation.component';
import { UserReservationDetailComponent } from './pages/user/user-reservation-detail/user-reservation-detail.component';
import { UserReservationserviceDetailComponent } from './pages/user/user-reservationservice-detail/user-reservationservice-detail.component';


import { BookingServiceDetailComponent } from './pages/booking-service/booking-service-detail/booking-service-detail.component';
import { BookingServiceResultComponent } from './pages/booking-service/booking-service-result/booking-service-result.component';
import { BookingServiceCheckoutComponent } from './pages/booking-service/booking-service-checkout/booking-service-checkout.component';

import { BookingAttivitaComponent } from './pages/booking-attivita/booking-attivita/booking-attivita.component';
import { BookingAttivitaDetailComponent } from './pages/booking-attivita/booking-attivita-detail/booking-attivita-detail.component';
import { BookingAttivitaCheckoutComponent } from './pages/booking-attivita/booking-attivita-checkout/booking-attivita-checkout.component';
import { QuestComponent } from './pages/quest/quest.component';

import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

const routes: Routes = [
  { path: '', redirectTo: 'home/it', pathMatch: 'full' },
  { path: 'home', redirectTo: 'home/it', pathMatch: 'full' },
  { path: 'return', redirectTo: 'home/return', pathMatch: 'full' },

  { path: 'home/:language', component: HomeComponent},
  { path: 'access/:language/:type', component: AccessComponent},
  { path: 'services/:type/:language', component: ServicesComponent},
  { path: 'information/:language', component: InformationComponent},
  { path: 'terms/:language/:type', component: TermsComponent},
  { path: 'contact/:language', component: ContactComponent},
  { path: 'quest/:language', component: QuestComponent},
  { path: 'destination/:language', component: DestinationComponent},
  { path: 'destination-detail/:language/:destinationId', component: DestinationDetailComponent},

  { path: 'booking-structure/:language', component: BookingStructureComponent }, //Lista di tutte le strutture
  { path: 'booking-checkout/:language/:id_struttura/:id_camera/:checkin/:checkout/:adulti/:bambini', component: BookingCheckoutComponent, canActivate: [ AngularFireAuthGuard ]}, //pagamento
  { path: 'booking-structure-detail/:language/:id_struttura', component: BookingStructureDetailComponent }, //dettaglio di una struttura con la lista delle camere
  { path: 'booking-structure-detail/:language/:id_struttura/:checkin/:checkout/:adulti/:bambini', component: BookingStructureDetailComponent }, //dettaglio di una struttura con la lista delle camere
  { path: 'booking-result/:language/:checkin/:checkout/:adulti/:bambini', component: BookingResultComponent }, //ricerca dei risultati
  { path: 'booking-result/:language/:checkin/:checkout/:paese/:adulti/:bambini', component: BookingResultComponent },
  { path: 'booking-room-detail/:language/:id_struttura/:id_camera/:checkin/:checkout/:adulti/:bambini', component: BookingRoomDetailComponent }, //dettaglio di una struttura con la lista delle camere

  { path: 'booking-service-result/:language/:checkin/:checkout/:adulti/:bambini', component: BookingServiceResultComponent }, //ricerca dei risultati
  { path: 'booking-service-result/:language/:checkin/:checkout/:paese/:adulti/:bambini', component: BookingServiceResultComponent },
  { path: 'booking-service-result/:language', component: BookingServiceResultComponent },
  { path: 'booking-service-detail/:language/:id_servizio', component: BookingServiceDetailComponent }, //dettaglio di una struttura con la lista delle camere
  { path: 'booking-service-detail/:language/:id_servizio/:checkin/:checkout/:adulti/:bambini', component: BookingServiceDetailComponent }, //dettaglio di una struttura con la lista delle camere
  { path: 'booking-service-checkout/:language/:id_servizio/:id_serviziodetail/:checkin/:checkout/:adulti/:bambini', component: BookingServiceCheckoutComponent, canActivate: [ AngularFireAuthGuard ]}, //pagamento

  { path: 'booking-attivita/:language/:id_reservation/:id_camera', component: BookingAttivitaComponent },
  { path: 'booking-attivita-checkout/:language/:id_reservation/:id_struttura/:id_camera/:id_att', component: BookingAttivitaCheckoutComponent },

  { path: 'user-update/:language', component: UserUpdateComponent, canActivate: [ AngularFireAuthGuard ]},
  { path: 'user-reservation/:language', component: UserReservationComponent, canActivate: [ AngularFireAuthGuard ]},
  { path: 'user-reservation-detail/:language/:id_reservation/:id_struttura/:id_camera', component: UserReservationDetailComponent, canActivate: [ AngularFireAuthGuard ] },
  { path: 'user-reservationservice-detail/:language/:id_reservation/:id_servizio/:id_serviziodetail', component: UserReservationserviceDetailComponent, canActivate: [ AngularFireAuthGuard ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
