import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../../service/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent implements OnInit {

  public language;
  public signupForm: FormGroup;
  public userData;
  public error;

  constructor(private route: ActivatedRoute, private router: Router, public formBuilder: FormBuilder, public afAuth: AngularFireAuth, public db: AngularFireDatabase, private _snackBar: MatSnackBar,) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
    });

    this.signupForm = formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      name: new FormControl('', Validators.compose([Validators.required])),
      surname: new FormControl('', Validators.compose([Validators.required])),
      condizione1: new FormControl(false),
    });
  }

  ngOnInit() {
    var self = this;
    this.afAuth.auth.onAuthStateChanged( user => {
      if(user){
        console.log(user);
        self.db.object('/users/'+user.uid).valueChanges().subscribe(action=>{
          console.log(action);
          if(action){
            self.userData = action;
            self.userData.uid = user.uid;
            self.signupForm = self.formBuilder.group({
              email: new FormControl(self.userData.email, Validators.compose([Validators.required,Validators.email])),
              name: new FormControl(self.userData.name, Validators.compose([Validators.required])),
              surname: new FormControl(self.userData.surname, Validators.compose([Validators.required])),
            });

            self.userData.uid = user.uid;
            console.log(self.userData);
          }
        });      
      }
    });
  }

  updateUser(){
    var self = this;

    if(self.signupForm.value.name && self.signupForm.value.surname && self.signupForm.value.email){
      this.db.object('/users/'+this.userData.uid).update({
        name: self.signupForm.value.name,
        surname: self.signupForm.value.surname,
        email: self.signupForm.value.email,
      });

      var message = 'Informazioni salvate.';
      this.openSnackBar(message, 'ok');

    }else{
      this.error = 'Inserisci tutti i campi';
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
