import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database'
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public language = 'it';
  public userData;
  public userMenu = false;

  public showLang = false;

  constructor(private router: Router, public http: HttpClient, public afAuth: AngularFireAuth, public db: AngularFireDatabase, public authService: AuthService) {

  }

  ngOnInit() {
    var self = this;
    this.afAuth.auth.onAuthStateChanged( user => {
      if(user){
        console.log(user);
        self.userData = user;
      }
    });
  }
  
  gotoAccess(label){
    this.router.navigate(['/'+label+'/'+this.language+'/login']);
  }

  gotoLink(label){
    this.userMenu= false;
    this.router.navigate(['/'+label+'/'+this.language]);
  }

  gotoGestionale(){
    window.open('http://managers.touringflat.com/web/index.php/user/signup0');
  }

  openMenu(){
    if(this.userMenu){
      this.userMenu= false;
    }else{
      this.userMenu=true;
    }
  } 

  esci(){
    this.userMenu = false;
    this.userData = null;
    this.authService.logoutUser();
    this.router.navigate(['/home/it']);
  }

  showLangMenu(){
    if(this.showLang){
      this.showLang = false;
    }else{
      this.showLang = true;
    }
  }

  selectLang(label){
    this.language=label;
    this.showLang = false;
  }
}
