import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Http } from '@angular/http';

@Component({
  selector: 'app-destination-detail',
  templateUrl: './destination-detail.component.html',
  styleUrls: ['./destination-detail.component.scss']
})
export class DestinationDetailComponent implements OnInit {
  
  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public language;
  public destinationId;
  public destinationItem;
  public cover = '';
  public immaginiList;

  constructor(private route: ActivatedRoute, private router: Router, private http: Http,) {
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.destinationId = params['destinationId'];
      this.getDestination();
    });
  }

  ngOnInit() {  
    window.scroll(0,0);
  }

  getDestination(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/destinazione/apidestinazione/apidestinazioneview?id='+this.destinationId)
    .subscribe(
      res => {
        console.log(res.json());
        var result = res.json();
        if(result){
          self.destinationItem = result[0];
          self.immaginiList = result[1];
          console.log(self.immaginiList);
          var img = result[1];
          if(img && img[0]){
            console.log(img);
            self.cover = self.urlbackend+'/'+img[0].url_immagine;
          }
          console.log(self.cover);
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

  textFormat(html){
    if(html){
      return html.replace(/\n/g, '<br/>');
    }
  }
  
}
