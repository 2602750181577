import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';

@Component({
  selector: 'app-user-reservationservice-detail',
  templateUrl: './user-reservationservice-detail.component.html',
  styleUrls: ['./user-reservationservice-detail.component.scss']
})
export class UserReservationserviceDetailComponent implements OnInit {

  public language;
  public userData;
  
  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public id_reservation;
  public id_servizio;
  public id_serviziodetail;

  public reservationdetail;
  public service;
  public servicedetail;

  public numgiorni;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, public dialog: MatDialog,) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.id_reservation = params['id_reservation'];
      this.id_servizio = params['id_servizio'];
      this.id_serviziodetail = params['id_serviziodetail'];

      this.getReservationdetail();
      this.getService();
      this.getServiceDetail();
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }
  
  getReservationdetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/apireservationdetail?id_reservation='+this.id_reservation)
    .subscribe(
      res => {
        console.log(res);
        self.reservationdetail = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  //le ultime 4 strutture inserite e validate
  getService(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/apiserviziodetail?id_servizio='+this.id_servizio)
    .subscribe(
      res => {
        console.log(res);
        self.service = res;
        console.log(self.service);
      },
      err => {
        console.log(err);         
      }
    )
  }
    
  getServiceDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/apiservdetail?id_servizio='+this.id_servizio+'&id_serviziodetail='+this.id_serviziodetail)
    .subscribe(
      res => {
        console.log(res);
        self.servicedetail = res;
        console.log(self.servicedetail);
      },
      err => {
        console.log(err);         
      }
    )
  }

  openModalMap(){
    let dialogRef = this.dialog.open(ModalMapComponent,{
      width: '650px',
      height: '550px',
      disableClose: false,
      data:{
        item: this.service[0][0]
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  getData(item){
    var myDate = item.split("-");
    var myDate1 = myDate[2].split(" ");
    var newDate = myDate1[0]+'-'+myDate[1]+'-'+myDate[0];   
    return newDate;
  }
}
