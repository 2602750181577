import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking-attivita-detail',
  templateUrl: './booking-attivita-detail.component.html',
  styleUrls: ['./booking-attivita-detail.component.scss']
})
export class BookingAttivitaDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
