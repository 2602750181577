import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database'
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-user-reservation',
  templateUrl: './user-reservation.component.html',
  styleUrls: ['./user-reservation.component.scss']
})
export class UserReservationComponent implements OnInit {

  public language;
  public userData;
  
  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public reservation;
  public reservationService;

  constructor(private route: ActivatedRoute, private router: Router, public afAuth: AngularFireAuth, public db: AngularFireDatabase, private http: HttpClient,) {
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
    });
  }

  ngOnInit() {
    window.scroll(0,0);
    var self = this;
    this.afAuth.auth.onAuthStateChanged( user => {
      if(user){
        console.log(user);
        self.db.object('/users/'+user.uid).valueChanges().subscribe(action=>{
          console.log(action);
          if(action){
            self.userData = action;
            self.userData.uid = user.uid;
            console.log(self.userData);
            self.getReservation();
            self.getReservationService();
          }
        });      
      }
    });
  }

  getReservation(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/struttura/apistruttura/apireservation?idfirebase='+this.userData.uid);
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apireservation?idfirebase='+this.userData.uid)
    .subscribe(
      res => {
        console.log(res);
        self.reservation = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  getReservationService(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/apireservation?idfirebase='+this.userData.uid)
    .subscribe(
      res => {
        console.log(res);
        self.reservationService = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  getData(item){
    if(item){
      var myDate = item.split("-");
      var myDate1 = myDate[2].split(" ");
      var newDate = myDate1[0]+'-'+myDate[1]+'-'+myDate[0];   
      return newDate;
    }
  }

  gotoReservationDetail(item){
    console.log(item);
    this.router.navigate(['/user-reservation-detail/'+this.language+'/'+item.id_prenotazione+'/'+item.id_struttura_prenotazione+'/'+item.id_camera_prenotazione]);
  }

  gotoReservationserviceDetail(item){
    console.log(item);
    this.router.navigate(['/user-reservationservice-detail/'+this.language+'/'+item.id_prenotazioneservizio+'/'+item.id_servizio_prenotazioneservizio+'/'+item.id_serviziodetail_prenotazioneservizio]);
  }
}
