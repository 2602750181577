import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public language;
  public type;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.type = params['type'];
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }
  
}
