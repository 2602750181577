import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalMessageComponent } from '../../../modules/struttura/modal-message/modal-message.component';

@Component({
  selector: 'app-booking-structure',
  templateUrl: './booking-structure.component.html',
  styleUrls: ['./booking-structure.component.scss']
})
export class BookingStructureComponent implements OnInit {

  public language;
  public results;
  public imamagini = {};

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  //mappa
  public mappa = false;
  public zoom = 6;
  public latMap = 41.9064878;
  public lonMap = 12.4536413;
  public coords = [];

  public checkin;
  public checkout;
  public paese = '';

  //search
  public inputSearch;
  public searchTable = false;
  public resultsPaese;
  public comuni;

  public adulti;
  public bambini;

  public datainizio;
  public datafine;

  public giorno = "2";

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, public dialog: MatDialog, private _snackBar: MatSnackBar) {
    this.getResult();
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.goModalMessage();
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  public listMarker :any = {};
  markerClicked($event){
    console.log($event);
  }

  gotoUrl(id){
    this.router.navigate(['/booking-structure-detail/'+this.language+'/'+id]);
  }
  
  goModalMessage(){
    let dialogRef = this.dialog.open(ModalMessageComponent, { 
      width: '600px',
      height: '400px',
      data: {
        message: 'Inserisci i dati di checkin, checkout, adulti e bambini per scoprire le disponibilità delle strutture.',
        language:this.language,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  

  getResult(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturaviewall', options)
    .subscribe(
      res => {
        console.log(res);
        if(res){
          self.results = res[0];
          var imamagini = res[1];

          self.results.forEach(element => {
            console.log(element);
            var doc = {
              id: element.id_struttura,
              zIndex : 1,
              lat: element.lat_struttura,
              lng: element.lng_struttura,
              iconUrl : {
                url: 'assets/marker/blue.svg',
                scaledSize: {
                  width: 40,
                  height: 40,
                },
              }
            }

            self.listMarker[ element.id_struttura] = {
              id: element.id_struttura,
              title: element.nome_struttura,
              citta: element.citta_struttura,
            };
            console.log(this.listMarker);
            self.coords.push(doc);
          });

          for(let i=0; i<imamagini.length; i++ ){
            if(!self.imamagini[imamagini[i].id_struttura_immagine]){
              self.imamagini[imamagini[i].id_struttura_immagine] = self.urlbackend+'/'+imamagini[i].url_immagine;
            }
          }
          
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

  goModalmap(item){
    let dialogRef = this.dialog.open(ModalMapComponent, { 
      width: '600px',
      height: '400px',
      data: {
        item: item,
        language:this.language,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

   gotoBookingStructureDetail(id_struttura){
    console.log(id_struttura);
    this.router.navigate(['/booking-structure-detail/'+this.language+'/'+id_struttura]);
  }

  setTimeStart(event){
    console.log(this.datainizio);
    console.log(event.value);
    if(event.value){

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkin1 = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;

      
      //var dt = event.value.getTime()/1000;
      //this.checkin=dt;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkin=this.checkin-offset;
      this.datainizio=event.value;
      this.checkin = checkin1;
      console.log(this.datainizio);
      console.log(this.checkin);
      console.log(this.checkout);

      if(this.checkin>=this.checkout){
        this.checkout = null;
        this.datafine = null;
      }
    }else{

    }
  }

  setTimeEnd(event){
    console.log(event.value);
    if(event.value){
      //this.checkout = event.value.getTime()/1000;
      //var offset = new Date().getTimezoneOffset()*60;
      //this.checkout=this.checkout-offset;

      const offset1 = new Date(event.value).getTimezoneOffset() * 60000;
      let checkout = ((new Date(event.value)).setHours(0, 0, 0) - offset1 ) / 1000;
      this.checkout = checkout;

      this.datafine=event.value;
      console.log(this.datafine);
    }else{

    }
  }

  filterDates = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if (timezone < 0)
     selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    else 
      selectedDay = d.setUTCHours(0,0,0,0);

    const today = (new Date()).setUTCHours(0,0,0,0);

    if (selectedDay >= today) {
      return true;
    }else
      return false;
  }

  filterDatesOut = (d: Date): boolean => {
    const timezone = d.getTimezoneOffset();
    let selectedDay = d.setUTCHours(0,0,0,0);
    // SE FUSORARIO E' DOPO UTC TIME AGGIUNGI UN GIORNO A UTC TIME
    // SE FUSORARIO E' PRIMA DI UTC TIME TOGLI UN GIORNO A UTC TIME

    if(timezone < 0){
      selectedDay = d.setUTCHours(0,0,0,0)+24*60*60*1000;
    }
    else{
      selectedDay = d.setUTCHours(0,0,0,0);
    }

    if(this.checkin){
      if(selectedDay <= this.checkin*1000){
        return false;
      }
    }
    return true;
  }

  search(){
    if(this.checkin){
      console.log(this.giorno);
      if((this.checkout==undefined || this.checkout=='undefined' || this.checkout==null || !this.checkout) && this.giorno=='2'){
        var message = 'Inserisci la data di checkout';
        this.openSnackBar(message, 'ok');
      }else{
        console.log(this.checkin);
        console.log(this.checkout);
        console.log(this.inputSearch);
        var paese = '';
        if(this.inputSearch){
          paese = this.inputSearch;
        }
  
        if(this.giorno=='1'){
          this.checkout = this.checkin;
        }
  
        if(this.bambini==undefined || this.bambini==null || !this.bambini || this.bambini=='null'){
          this.bambini = 0;
        }
  
        if(this.adulti==undefined || this.adulti==null || !this.adulti || this.adulti=='null'){
          this.adulti=1;
        }
        
        if(paese){
          this.router.navigate(['/booking-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+paese+'/'+this.adulti+'/'+this.bambini]);
        }else{
          this.router.navigate(['/booking-result/'+this.language+'/'+this.checkin+'/'+this.checkout+'/'+this.adulti+'/'+this.bambini]);
        }
      }

    }else{
      var message = 'Inserisci la data di checkin';
      this.openSnackBar(message, 'ok');
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
