import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public language = 'it';

  public name;
  public surname;
  public email;
  public phone;
  public text;
  public terms;
  
  constructor(private router: Router, private http: HttpClient) { 

  }

  ngOnInit() {
  }

  sendEmail(email){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    //info@touringflat.com è il mittende di questa email.
    //ci sarà il rilascio di una email copia che si troverà nelle email ricevute.

    var body = {
        toEmail: email,
        textEmail:`<b>Richiesta al servizio clienti Touringflat. Di seguito il riepilogo della richiesta, la contatteremo al più presto.</b><br>
        Nome: `+self.name+`<br>
        Cognome: `+self.surname+`<br>
        Email: `+self.email+`<br>
        Telefono: `+self.phone+`<br>
        Richiesta: `+self.text+`<br>`,
        subjectEmail:"Richiesta assistenza",
        showButtons: false
    }

    self.http.post('https://api.composingstudio.com/v1/touringflat/emails/info', body, options)
    .subscribe(
      res => {
        console.log(res);
        self.messageOk = 'Richiesta inviata.';
        self.message=null;
        self.name = null;
        self.surname = null;
        self.email = null;
        self.terms= false;
      },
      err => {
        console.log(err); 
        self.message = 'Si è verificato un errore.';
      }
    )
  }

  message;
  messageOk;
  send(){
    this.messageOk = null;
    this.message = null;
    if(this.name && this.surname && this.email && this.phone && this.text){
      if(this.terms){
        this.sendEmail(this.email);
      }else{
        this.message = 'Accetta termini e condizioni.';
      }
    }else{
      this.message = 'Inserisci tutti i dati.';
    }
  }

  gotoTerms(type){
    this.router.navigate(['/terms/'+this.language+'/'+type]);
  }
}
