import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-map',
  templateUrl: './modal-map.component.html',
  styleUrls: ['./modal-map.component.scss']
})
export class ModalMapComponent implements OnInit {

  public item;
  public lat;
  public lng;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ModalMapComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.item = data.item;
    console.log(this.item);
    this.lat = Number(data.item.lat_struttura);
    this.lng = Number(data.item.lng_struttura);
  }

  ngOnInit() {

  }

}
