import { Component, OnInit,ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalRegolamentoComponent } from '../../../modules/struttura/modal-regolamento/modal-regolamento.component';
import { MediaObserver } from '@angular/flex-layout';

declare var stripe: any;
declare var elements: any

@Component({
  selector: 'app-booking-checkout',
  templateUrl: './booking-checkout.component.html',
  styleUrls: ['./booking-checkout.component.scss']
})
export class BookingCheckoutComponent implements OnInit {

  @ViewChild('cardInfo', {static: false}) cardInfo: ElementRef;
  card: any;
  cardHandler = this.onChange.bind(this);

  @ViewChild('cardInfoMobile', {static: false}) cardInfoMobile: ElementRef;
  cardMobile: any;
  cardHandlerMobile = this.onChange.bind(this);


  public language;
  public id_struttura;
  public id_camera;
  public checkin;
  public checkout;

  public type_pagamento_struttura = 0;

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public structure;
  public userData;
  public room;
  public numgiorni = 0;
  public error;

  public name;
  public surname;
  public email;
  public email_other;
  public telefono;
  public idToken;
  public adulti;
  public bambini;
  public stripe;

  public regolamento= false;
  public prezzo_totale;

  constructor(private router: Router, private http: HttpClient, public media: MediaObserver, private cd: ChangeDetectorRef, private _snackBar: MatSnackBar, private route: ActivatedRoute, public dialog: MatDialog, public afAuth: AngularFireAuth, public db: AngularFireDatabase,) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.id_struttura = params['id_struttura'];
      this.id_camera = params['id_camera'];
      this.checkin = params['checkin'];
      this.checkout = params['checkout'];
      this.adulti = params['adulti'];
      this.bambini = params['bambini'];
      this.numgiorni = (this.checkout-this.checkin)/86400;

      this.getStructureDetail();
      this.getRoomDetail();
      this.getStripe();

      this.afAuth.auth.onAuthStateChanged( user => {
        if(user){
          console.log(user.email);
          this.email = user.email;
          this.getPrice();
        }
      });
    });
  }

  ngOnInit() {
    window.scroll(0,0);
    var self = this;
    this.afAuth.auth.onAuthStateChanged( user => {
      if(user){
        console.log(user);
        self.db.object('/users/'+user.uid).valueChanges().subscribe(action=>{
          console.log(action);
          if(action){
            self.userData = action;
            self.name = self.userData.name;
            self.surname = self.userData.surname;
            self.email = self.userData.email;
            self.userData.uid = user.uid;
            console.log(self.userData);
          }
        });      

        firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
          self.idToken = idToken;
        });
      }
    });
  }
  
  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  public mobile:any;
  ngAfterViewInit() {
    if(this.media.isActive('xl')){
      this.mobile = false;
    }else if(this.media.isActive('lg')){
      this.mobile = false;
    }else if(this.media.isActive('md')){
      this.mobile = false;
    }else if(this.media.isActive('sm')){
      this.mobile = true;
    }else if(this.media.isActive('xs')){
      this.mobile = true;
    }

    const style = {
      base: {
        lineHeight: '24px',
        fontSize: '16px',
        '::placeholder': {
          color: '#484848'
        }
      }
    };
  
    if(this.mobile==false){
      this.card = elements.create('card', { style });
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener('change', this.cardHandler);  
    }else{
      this.cardMobile = elements.create('card', { style });
      this.cardMobile.mount(this.cardInfoMobile.nativeElement);
      this.cardMobile.addEventListener('change', this.cardHandlerMobile);  
    }
  }

  ngOnDestroy() {
    if(this.mobile==false){
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    } else {
      this.cardMobile.removeEventListener('change', this.cardHandler);
      this.cardMobile.destroy();
    }
  }

  getPrice(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apirommprice?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera+'&checkin='+this.checkin+'&checkout='+this.checkout)
    .subscribe(
      res => {
        console.log(res);
        self.prezzo_totale = res;
        self.getStrutturaperc();
      },
      err => {
        console.log(err);         
      }
    )
  }

  
  public prezzo_scontato = 0;
  public sconto_percentuale = 0;
  getStrutturaperc(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturaperc?id_struttura='+this.id_struttura+'&email='+self.email);

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturaperc?id_struttura='+this.id_struttura+'&email='+self.email)
    .subscribe(
      res => {
        console.log(res);
        if(res){
          
          if(res['percentuale_strutturaperc']){
            self.sconto_percentuale = res['percentuale_strutturaperc']; 
            console.log(res['percentuale_strutturaperc']);
            var prezzo_scontato = self.prezzo_totale - ((res['percentuale_strutturaperc']*self.prezzo_totale)/100);
            self.prezzo_scontato = Math.round(prezzo_scontato);
            console.log(self.prezzo_scontato);
          }else{
            self.prezzo_scontato = self.prezzo_totale;
          }
        }
      },
      err => {
        console.log(err);         
      }
    )
  }
  
  //le ultime 4 strutture inserite e validate
  getStructureDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturadetail?id_struttura='+this.id_struttura)
    .subscribe(
      res => {
        console.log(res);
        self.structure = res;
        console.log(self.structure);
        if(self.structure[0][0]){
          self.type_pagamento_struttura = self.structure[0][0].type_pagamento_struttura;
        }
      },
      err => {
        console.log(err);         
      }
    )
  }

    
  getRoomDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiroomdetail?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera)
    .subscribe(
      res => {
        console.log(res);
        self.room = res;
        console.log(self.room);
      },
      err => {
        console.log(err);         
      }
    )
  }

  getStripe(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistripe?id_struttura='+this.id_struttura)
    .subscribe(
      res => {
        console.log(res);
        self.stripe = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  public spinnerShow = false;
  paga(){
    var self = this;
    if(this.regolamento){
      if(this.type_pagamento_struttura==0){
        this.spinnerShow=true;
        stripe.createPaymentMethod({
          type: 'card',
          card: self.card,
          billing_details: {
            name: self.name+' '+self.surname,
          }
        }).then(function(result){
          console.log(result);
          if(result.paymentMethod){
            var paymentMethod = result.paymentMethod.id;

            const headers = new HttpHeaders({
              'Authorization': self.idToken,
              'Content-Type': 'application/json',
            });
      
            var options = {
              headers: headers,
            };

            var amount = self.prezzo_totale;
            if(self.prezzo_scontato){
              amount = self.prezzo_scontato;
            } 

            var body = {
              customerId:self.userData.customerId,
              merchantId:self.stripe.merchant_id_stripe,
              paymentMethod: paymentMethod,
              amount:amount,
              fee:self.stripe.fee_stripe,
              currency:"eur",
              description:self.userData.name+' '+self.userData.surname+' '+self.structure[0][0].nome_struttura+' - '+self.room[0][0].nome_camera
            }

            //api dove ti passo il payment method
            self.http.post('https://api.composingstudio.com/v1/touringflat/stripe/payment-intent/', body, options)
            .subscribe(
              res => {
                console.log(res);
                var client_secret = res['client_secret'];

                self.confirm(paymentMethod, client_secret);
                
              },
              err => {
                console.log(err);
                self.spinnerShow = false;
                self.error = 'Si è verificato un errore.';
              }
            )   
          }else{
            self.spinnerShow = false;
            self.error = result.error.message;
            //header = this.label['register'].attenzione[this.language];
            //button = this.label['register'].riprova[this.language];
            //message = 'Inserisci tutte le informazioni richieste.';
            //this.supportService.presentAlert(header, message, button);
          }
        })
      }else{
        //pagamento senza carta di credito

        //pagamento_prenotazione = 1 pagamento in struttura
        //pagamento_prenotazione = 2 pagamento tramite portale
        self.sendEmail(this.userData.email);
        if(self.email_other){
          self.sendEmail(this.email_other);
        }
        this.setPrenotazione(1);
      }
    }else{
      this.error = 'Devi accettare il regolamento della struttura';
     // this.openSnackBar(message, 'OK');
    }
  }

  async confirm(paymentMethod, client_secret){ 
    var self = this;
    let payment = await stripe.confirmCardPayment(client_secret,{
      payment_method: paymentMethod
    });

    console.log(payment);
    if(payment.error) {
      this.error = payment.error;
    }else{
      self.sendEmailCarta(self.userData.email);
      if(self.email_other){
        self.sendEmailCarta(this.email_other);
      }
      self.setPrenotazione2(2);
    }
  }

  pagaMobile(){
    var self = this;
    if(this.regolamento){
      if(this.type_pagamento_struttura==0){
        this.spinnerShow=true;
        stripe.createPaymentMethod({
          type: 'card',
          card: self.cardMobile,
          billing_details: {
            name: self.name+' '+self.surname,
          }
        }).then(function(result){
          console.log(result);
          if(result.paymentMethod){
            var paymentMethod = result.paymentMethod.id;

            const headers = new HttpHeaders({
              'Authorization': self.idToken,
              'Content-Type': 'application/json',
            });
      
            var options = {
              headers: headers,
            };

            var amount = self.prezzo_totale;
            if(self.prezzo_scontato){
              amount = self.prezzo_scontato;
            } 

            var body = {
              customerId:self.userData.customerId,
              merchantId:self.stripe.merchant_id_stripe,
              paymentMethod:paymentMethod,
              amount: amount,
              fee:self.stripe.fee_stripe,
              currency:"eur",
              description:self.userData.name+' '+self.userData.surname+' '+self.structure[0][0].nome_struttura+' - '+self.room[0][0].nome_camera
            }

            //api dove ti passo il payment method
            self.http.post('https://api.composingstudio.com/v1/touringflat/stripe/payment-intent/', body, options)
            .subscribe(
              res => {
                console.log(res);
                var client_secret = res['client_secret'];
                self.confirm(paymentMethod, client_secret);
              },
              err => {
                console.log(err);
                self.spinnerShow = false;
                self.error = 'Si è verificato un errore.';
              }
            )   
          }else{
            self.spinnerShow = false;
            self.error = result.error.message;
            //header = this.label['register'].attenzione[this.language];
            //button = this.label['register'].riprova[this.language];
            //message = 'Inserisci tutte le informazioni richieste.';
            //this.supportService.presentAlert(header, message, button);
          }
        })
      }else{
        //pagamento senza carta di credito

        //pagamento_prenotazione = 1 pagamento in struttura
        //pagamento_prenotazione = 2 pagamento tramite portale
        self.sendEmail(this.userData.email);
        if(self.email_other){
          self.sendEmail(this.email_other);
        }
        this.setPrenotazione(1);
      }
    }else{
      this.error = 'Devi accettare il regolamento della struttura';
     // this.openSnackBar(message, 'OK');
    }
  }

  //invio i dati della prenotazione
  setPrenotazione(pagamento_prenotazione){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    var utente = this.userData.name+' '+this.userData.surname+' '+this.userData.email;

    var amount = self.prezzo_totale; //quello che paga lutente
    if(self.prezzo_scontato){
      amount = self.prezzo_scontato;
    } 

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apisetprenotazione?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera+'&checkin='+this.checkin+'&checkout='+this.checkout+'&prezzo='+amount+'&adulti='+this.adulti+'&bambini='+this.bambini+'&id_utentefirebase='+this.userData.uid+'&utente='+utente+'&pagamento_prenotazione='+pagamento_prenotazione+'&prezzo_totale='+self.prezzo_totale+'&prezzo_sconto='+self.prezzo_scontato+'&percentuale='+self.sconto_percentuale+'&email='+this.userData.email, options)
    .subscribe(
      res => {
        console.log(res);
        var message = 'Prenotazione effetuata con successo. Scopri le attività da poter prenotare per questa struttura.';
        self.openSnackBar(message, 'OK');
        self.router.navigate(['/user-reservation-detail/'+this.language+'/'+res+'/'+this.id_struttura+'/'+this.id_camera]);
      },
      err => {
        console.log(err);       
        self.error = 'Si è verificato un errore';
      }
    )
  }


  setPrenotazione2(pagamento_prenotazione){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    var utente = this.userData.name+' '+this.userData.surname+' '+this.userData.email;
                
    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apisetprenotazione?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera+'&checkin='+this.checkin+'&checkout='+this.checkout+'&prezzo='+this.prezzo_totale+'&adulti='+this.adulti+'&bambini='+this.bambini+'&id_utentefirebase='+this.userData.uid+'&utente='+utente+'&pagamento_prenotazione='+pagamento_prenotazione+'&prezzo_totale='+self.prezzo_totale+'&prezzo_sconto='+self.prezzo_scontato+'&percentuale='+self.sconto_percentuale+'&email='+this.userData.email, options)
    .subscribe(
      res => {
        console.log(res);
        var message = 'Prenotazione effetuata con successo. Scopri le attività da poter prenotare per questa struttura.';
        self.openSnackBar(message, 'OK');
        self.router.navigate(['/user-reservation-detail/'+this.language+'/'+res+'/'+this.id_struttura+'/'+this.id_camera]);
      },
      err => {
        console.log(err);       
        self.error = 'Si è verificato un errore';
      }
    )
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  goModalRegolamento(){
    let dialogRef = this.dialog.open(ModalRegolamentoComponent, { 
      width: '600px',
      height: '400px',
      data: {
        structure: this.structure[0][0],
        language:this.language,
      }
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  sendEmail(email){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };


    var datein = new Date(this.checkin*1000);
    var dateinStamp = datein.getDate()+'/'+(datein.getMonth()+1)+'/'+datein.getFullYear();

    var dateout = new Date(this.checkout*1000);
    var dateoutStamp = dateout.getDate()+'/'+(dateout.getMonth()+1)+'/'+dateout.getFullYear();

    self.name = self.userData.name;
    self.surname = self.userData.surname;
    self.email = self.userData.email;

    var body = {
        toEmail: email,
        textEmail:`<b>Prenotazione effettuata con successo.</b><br>
        Nome e Cognome: `+self.name+` `+self.surname+`<br>
        Email: `+self.email+`<br>
        Data checkin: `+dateinStamp+` - data checkout: `+dateoutStamp+`<br>
        Le ricordiamo che l’intera somma verrà pagata in struttura. Il totale da pagare è di `+self.prezzo_totale+`€.<br>
        `+self.structure[0][0].nome_struttura+` le da il benvenuto.<br>
        Ci troviamo in `+self.structure[0][0].indirizzo_struttura+` `+self.structure[0][0].civico_struttura+` `+self.structure[0][0].citta_struttura+` `+self.structure[0][0].cap_struttura+`<br>
        Per qualsiasi necessità ci può contattare al numero `+self.structure[0][0].telefono_struttura+` - `+self.structure[0][0].cell_struttura+`<br>
        Oppure via email a `+self.structure[0][0].email_struttura+`<br>
        Siamo sempre a vostra disposizione`,
        subjectEmail:"PRENOTAZIONE CON TOURINGFLAT",
        showButtons: false
    }

    self.http.post('https://api.composingstudio.com/v1/touringflat/emails/customercare', body, options)
    .subscribe(
      res => {
        console.log(res);
        self.sendEmailAdmin(self.structure[0][0].email_struttura, 1);
      },
      err => {
        console.log(err); 
      }
    )
  }

  sendEmailCarta(email){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };


    var datein = new Date(this.checkin*1000);
    var dateinStamp = datein.getDate()+'/'+(datein.getMonth()+1)+'/'+datein.getFullYear();

    var dateout = new Date(this.checkout*1000);
    var dateoutStamp = dateout.getDate()+'/'+(dateout.getMonth()+1)+'/'+dateout.getFullYear();

    var body = {
        toEmail: email,
        textEmail:`<b>Prenotazione effettuata con successo.</b><br>
        Nome e Cognome: `+self.name+` `+self.surname+`<br>
        Email: `+self.email+`<br>
        Struttura:`+self.structure[0][0].nome_struttura+` - nome camera: `+self.room[0][0].nome_camera+`
        Numero di adulti: `+self.adulti+` - numero di bambini: `+self.bambini+`
        Data checkin: `+dateinStamp+` - data checkout: `+dateoutStamp+`<br>
        L’intera somma è stata addebitata tramite carta di credito. Il totale pagato è di `+self.prezzo_totale+`€.<br>
        `+self.structure[0][0].nome_struttura+` le da il benvenuto.<br>
        Ci troviamo in `+self.structure[0][0].indirizzo_struttura+` `+self.structure[0][0].civico_struttura+` `+self.structure[0][0].citta_struttura+` `+self.structure[0][0].cap_struttura+`<br>
        Per qualsiasi necessità ci può contattare al numero `+self.structure[0][0].telefono_struttura+` - `+self.structure[0][0].cell_struttura+`<br>
        Oppure via email a `+self.structure[0][0].email_struttura+`<br>
        Siamo sempre a vostra disposizione`,
        subjectEmail:"PRENOTAZIONE CON TOURINGFLAT",
        showButtons: false
    }

    self.http.post('https://api.composingstudio.com/v1/touringflat/emails/customercare', body, options)
    .subscribe(
      res => {
        console.log(res);
        
        self.sendEmailAdmin(self.structure[0][0].email_struttura, 0);
      },
      err => {
        console.log(err); 
      }
    )
  }

  sendEmailAdmin(email, type){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };


    var datein = new Date(this.checkin*1000);
    var dateinStamp = datein.getDate()+'/'+(datein.getMonth()+1)+'/'+datein.getFullYear();

    var dateout = new Date(this.checkout*1000);
    var dateoutStamp = dateout.getDate()+'/'+(dateout.getMonth()+1)+'/'+dateout.getFullYear();

    if(type==0){
      var body = {
          toEmail: email,
          textEmail:`<b>Prenotazione struttura.</b><br>
          Struttura:`+self.structure[0][0].nome_struttura+` - nome camera: `+self.room[0][0].nome_camera+`
          Numero di adulti: `+self.adulti+` - numero di bambini: `+self.bambini+`
          Data checkin: `+dateinStamp+` - data checkout: `+dateoutStamp+`<br>
          L’intera somma è stata addebitata tramite carta di credito. Il totale pagato è di `+self.prezzo_totale+`€.<br>
          Nome e cognome: `+self.name+` `+self.surname+` email: `+self.email+` `+self.telefono,
          subjectEmail:"PRENOTAZIONE CON TOURINGFLAT",
          showButtons: false
      }
    }else{
      var body = {
        toEmail: email,
        textEmail:`<b>Prenotazione struttura.</b><br>
        Struttura:`+self.structure[0][0].nome_struttura+` - nome camera: `+self.room[0][0].nome_camera+`
        Numero di adulti: `+self.adulti+` - numero di bambini: `+self.bambini+`
        Data checkin: `+dateinStamp+` - data checkout: `+dateoutStamp+`<br>
        L’intera somma verrà pagata in struttura. Il totale da pagare è di `+self.prezzo_totale+`€.<br>
        Nome e cognome: `+self.name+` `+self.surname+` email: `+self.email+` `+self.telefono,
        subjectEmail:"PRENOTAZIONE CON TOURINGFLAT",
        showButtons: false
      }
    }

    self.http.post('https://api.composingstudio.com/v1/touringflat/emails/amministrazione', body, options)
    .subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log(err); 
      }
    )
  }
}