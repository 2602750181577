import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ModalMapComponent } from '../../../modules/struttura/modal-map/modal-map.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, TransitionCheckState } from '@angular/material';

declare function generate() : void;

@Component({
  selector: 'app-user-reservation-detail',
  templateUrl: './user-reservation-detail.component.html',
  styleUrls: ['./user-reservation-detail.component.scss']
})
export class UserReservationDetailComponent implements OnInit {

  public language;
  public userData;
  
  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public id_reservation;
  public id_struttura;
  public id_camera;

  public reservationdetail;
  public structure;
  public room;
  public attivita;

  public numgiorni;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, public dialog: MatDialog,) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.id_reservation = params['id_reservation'];
      this.id_struttura = params['id_struttura'];
      this.id_camera = params['id_camera'];

      this.getReservationdetail();
      this.getStructureDetail();
      this.getRoomDetail();
      this.getPrenotazioneatt();
    });
  }

  ngOnInit() {
    window.scroll(0,0);
  }

  getReservationdetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apireservationdetail?id_reservation='+this.id_reservation)
    .subscribe(
      res => {
        console.log(res);
        self.reservationdetail = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  //le ultime 4 strutture inserite e validate
  getStructureDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apistrutturadetail?id_struttura='+this.id_struttura)
    .subscribe(
      res => {
        console.log(res);
        self.structure = res;
        console.log(self.structure);
      },
      err => {
        console.log(err);         
      }
    )
  }
    
  getRoomDetail(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    var options = {
      headers: headers,
    };

    self.http.get(this.urlbackend+'/index.php/struttura/apistruttura/apiroomdetail?id_struttura='+this.id_struttura+'&id_camera='+this.id_camera)
    .subscribe(
      res => {
        console.log(res);
        self.room = res;
        console.log(self.room);
      },
      err => {
        console.log(err);         
      }
    )
  }

  getPrenotazioneatt(){
    var self = this;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    
    var options = {
      headers: headers,
    };

    console.log(this.urlbackend+'/index.php/attivita/apiattivita/apigetprenotazione?idprenotazione='+this.id_reservation);
    
    self.http.get(this.urlbackend+'/index.php/attivita/apiattivita/apigetprenotazione?idprenotazione='+this.id_reservation)
    .subscribe(
      res => {
        console.log(res);
        self.attivita = res;
      },
      err => {
        console.log(err);         
      }
    )
  }

  openModalMap(){
    let dialogRef = this.dialog.open(ModalMapComponent,{
      width: '650px',
      height: '550px',
      disableClose: false,
      data:{
        item: this.structure[0][0]
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  getData(item){
    var myDate = item.split("-");
    var myDate1 = myDate[2].split(" ");
    var newDate = myDate1[0]+'-'+myDate[1]+'-'+myDate[0];   
    return newDate;
  }

  gotoAttivita(){
    this.router.navigate(['/booking-attivita/'+this.language+'/'+this.id_reservation+'/'+this.id_camera]);
  }
}
