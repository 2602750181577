import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpClient, HttpHeaderResponse } from '@angular/common/http';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  public language;
  public type;

  //public urlbackend = 'http://localhost/touringflatbackend/web';
  public urlbackend = 'https://managers.touringflat.com/web';

  public results;

  //mappa
  public mappa = false;
  public zoom = 6;
  public latMap = 41.9064878;
  public lonMap = 12.4536413;
  public coords = [];

  public checkin;
  public checkout;
  public paese = '';

  public adulti;
  public bambini;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, ) { 
    this.getResult();
    this.route.params.subscribe((params: ParamMap) => {
      this.language = params['language'];
      this.type = params['type'];
    });

  }

  ngOnInit() {
    window.scroll(0,0);
  }

  getResult(){
    var self = this;
    self.http.get(this.urlbackend+'/index.php/servizio/apiservizio/apiservizioviewall')
    .subscribe(
      res => {
        console.log(res);
        self.results = res;

        self.results.forEach(element => {
          console.log(element);
          var doc = {
            id: 1,
            zIndex : 1,
            lat: element.lat_servizio,
            lng: element.lng_servizio,
            /*iconUrl : {
              url: 'assets/img/Icon_call.svg',
              scaledSize: {
                width: 40,
                height: 40,
              },
            }*/
            
          }
          self.coords.push(doc);
        });
      },
      err => {
        console.log(err);         
      }
    )
  }

  gotoBookingServiceDetail(id_servizio){
    console.log(id_servizio);
    this.router.navigate(['/booking-service-detail/'+this.language+'/'+id_servizio]);
  }
}
