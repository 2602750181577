import { Component, OnInit } from '@angular/core';
import { Solution, GoogleObj } from './models/solution.model';
import { SolutionService } from './service/translate/solution.service';
import { GoogletranslateService } from './service/translate/google.translation.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tourbooking';
  lang = new FormControl('en');

  data: Solution = {
    title: "titolo",
    description: "descrizione",
    detail: "dettaglio"
  };

  private translateBtn: any;

  constructor (private solution: SolutionService, private google: GoogletranslateService){
  }

  ngOnInit(){
    this.solution.getSolution().subscribe(res => this.data =res);
    this.translateBtn = document.getElementById('translatebtn');
    console.log(this.translateBtn);
  }

  send() {
    const googleObj : GoogleObj = {
      q:[this.data.title, this.data.description, this.data.detail],
      target : this.lang.value
    }

    this.translateBtn.disables = true;

    this.google.translate(googleObj).subscribe(
      (res:any) => {
        this.translateBtn.disabled = false;
        this.data = {
          title: res.data.translations[0].translatedText,
          description: res.data.translations[1].translatedText,
          detail: res.data.translations[2].translatedText
        };
        console.log(this.data);
      },
      err => {
        console.log(err);
      }
    );
  }
}
